import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchOptions } from '../../../store/slices/contentSlice'
import { createGroupSession } from '../../../store/apis/contentApi'
import { isOstedhy, isTakiacademy } from '../../../utils/workspaceChecker'
import { getFormConfig } from '../../../utils/getFormConfig'
import { openModal } from '../../../store/slices/modalsSlice'
import { toast } from 'react-toastify'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dialog } from '@mui/material'
import { Button } from '../../Form'
import { CustomCheckbox } from '../../Checkbox'
import { Input, MultiSelect, StandardInput } from '../../Inputs'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icon.svg'
import Loader from '../../Loader'
import { INPUT_TYPES } from '../../../constants'

const GroupSession = ({ id, open, handleClose }) => {
  const {
    fields,
    initial_state,
    data: formData,
    schema,
  } = getFormConfig().groupSession
  const { register, handleSubmit, control, formState, watch, setValue, reset } =
    useForm({
      resolver: yupResolver(schema),
      defaultValues: initial_state(),
    })
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const watchData = watch()

  const renderFormInputs = () => {
    return fields.map(field => {
      const { type, label, id, optionDisplayAttribute, icon, searchAttribute } =
        field
      switch (type) {
        case INPUT_TYPES.asyncMultiSelect:
          return (
            <Controller
              key={id}
              name={id}
              control={control}
              defaultValue={watchData[id]}
              render={({ field: { onChange, value } }) => {
                return (
                  <MultiSelect
                    key={id}
                    id={id}
                    name={id}
                    label={label}
                    onChange={onChange}
                    searchAttribute={searchAttribute}
                    value={value}
                    loadOptions={
                      isOstedhy && id === 'subjectsOfTeacher'
                        ? fetchOptions[id].bind(null, watchData?.teacherId?.id)
                        : fetchOptions[id]
                    }
                    optionsSelected={watchData[id]}
                    setOption={value => onChange(id, value)}
                    optionDisplayAttribute={optionDisplayAttribute}
                    error={
                      formState.errors[id] && {
                        state: true,
                        message: formState.errors[id].message,
                      }
                    }
                    Icon={icon}
                    placeholder='Select'
                  />
                )
              }}
            />
          )
        case INPUT_TYPES.asyncSelect:
          return (
            <Controller
              key={id}
              name={id}
              control={control}
              defaultValue={watchData[id]}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  key={id}
                  id={id}
                  name={id}
                  label={label}
                  searchAttribute={searchAttribute}
                  onChange={onChange}
                  value={value}
                  loadOptions={fetchOptions[id]}
                  setOption={value => onChange(id, value)}
                  optionDisplayAttribute={optionDisplayAttribute}
                  optionsSelected={watchData[id]}
                  error={
                    formState.errors[id] && {
                      state: true,
                      message: formState.errors[id].message,
                    }
                  }
                  Icon={icon}
                  isMulti={false}
                  placeholder='Select'
                />
              )}
            />
          )
        case INPUT_TYPES.number:
          return (
            <Input
              key={id}
              label={label}
              id={id}
              name={id}
              register={register}
              placeholder={`Enter ${label}`}
              onChange={e => setValue(id, e.target.value)}
              type={'number'}
              value={watchData[id]}
              error={formState.errors[id]}
            />
          )
        case INPUT_TYPES.checkbox:
          return (
            <Controller
              key={id}
              name={id}
              control={control}
              defaultValue={watchData[id]}
              render={({ field: { onChange, value } }) => {
                return (
                  <CustomCheckbox
                    key={id}
                    onChange={() => onChange(value === 1 ? 0 : 1)}
                    checked={!!value}
                    label={label}
                  />
                )
              }}
            />
          )
        default:
          return null
      }
    })
  }

  const handleSubmitGroupSession = () => {
    setIsLoading(true)
    dispatch(createGroupSession(formData(watchData)))
      .then(res => {
        if (res.payload.status === 201 || res.payload.status === 200) {
          toast.success(
            isOstedhy
              ? 'Group Session successfully created'
              : 'Created Hessaty Chapter successfully',
          )

          let createdGroupSession
          if (isTakiacademy)
            createdGroupSession = {
              traineeship: res.payload.data.payload,
              groupSessions: true,
            }
          if (isOstedhy)
            createdGroupSession = {
              groupSessions: res.payload.data.payload,
            }

          dispatch(openModal('schedule_session', createdGroupSession))
          reset(initial_state())
          handleClose(id)
        }
      })
      .catch(() => toast.error('Something went wrong', { autoClose: 300 }))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id)
        }
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='ta-modal add-video-modal '
      scroll='body'>
      <div className='add-group_session'>
        <div className='add-group_session__header'>
          <div>
            <span className='subtitle'>Schedule Group Session</span>
            <span className='label'>Group Session</span>
          </div>
          <span className='close-btn' onClick={() => handleClose(id)}></span>
        </div>
        <form onSubmit={handleSubmit(handleSubmitGroupSession)}>
          <StandardInput
            icon={<CalendarIcon />}
            label='groupSession'
            name='name'
            id={'name'}
            placeholder='Enter your groupSession Name'
            register={register}
            required={true}
            error={
              formState.errors['name'] && {
                status: true,
                message: 'Name is required',
              }
            }
          />
          {renderFormInputs()}

          <Button type='submit'>
            {isLoading ? <Loader /> : 'Create Group Session'}
          </Button>
        </form>
      </div>
    </Dialog>
  )
}
export default GroupSession
