import { createSlice } from '@reduxjs/toolkit'
// import { USER_ACTIVITIES } from '../../constants'
// import { nanoid } from 'nanoid'
// import * as dayjs from 'dayjs'

// const initialiseActivities = () => {
//   let res = USER_ACTIVITIES.reduce((acc, act) => {
//     const exist = acc.findIndex(item =>
//       dayjs(item.date).isSame(act.date, 'day'),
//     )
//     if (exist >= 0) {
//       acc[exist] = {
//         ...acc[exist],
//         activities: [...acc[exist].activities, act],
//       }
//     } else {
//       acc.push({
//         id: nanoid(10),
//         date: dayjs(act.date).format('YYYY-MM-DD'),
//         activities: [act],
//       })
//     }
//     return acc
//   }, [])

//   return res
// }

const initialState = {
  userActivities: [],
}

export const userActivitySlice = createSlice({
  name: 'userActivity',
  initialState,
  reducers: {},
})

// export const {} = userActivitySlice.actions

export default userActivitySlice.reducer
