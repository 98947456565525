import takiIcon from '../assets/icons/taki_academy_thumbnail.svg'
import ostedhyIcon from '../assets/icons/ostedhy_thumbnail.svg'
import { formatIntervalDateToGmt } from '../utils/customDateFormats'

export const INTERVAL_REPETITIONS_DEFAULT = {
  repetitions: 1,
  selectedInterval: {
    label: 'Daily',
    value: 1,
  },
}

const COLORS = {
  1: {
    hex: '#7165EF',
    background: '#EEEDFD',
  },
  2: {
    hex: '#CC4500',
    background: '#FFF1EB',
  },
  3: {
    hex: '#1DCC00',
    background: '#EEFFEB',
  },
  4: {
    hex: '#B9133A',
    background: ' #FDECF0',
  },
  5: {
    hex: '#7165EF',
    background: '#EEEDFD',
  },
  6: {
    hex: '#CC4500',
    background: '#FFF1EB',
  },
  7: {
    hex: '#1DCC00',
    background: '#EEFFEB',
  },
  8: {
    hex: '#B9133A',
    background: ' #FDECF0',
  },

  9: {
    hex: '#FFA500',
    background: 'rgba(255, 165, 0, 0.1)',
  },
  10: {
    hex: '#008080',
    background: 'rgba(0, 128, 128, 0.1)',
  },
  11: {
    hex: '#FFD700',
    background: 'rgba(255, 215, 0, 0.1)',
  },
  12: {
    hex: '#000080',
    background: 'rgba(0, 0, 128, 0.1)',
  },
  14: {
    hex: '#545454',
    background: 'rgba(192, 192, 192, 0.1)',
  },
  13: {
    hex: '#4B0082',
    background: 'rgba(75, 0, 130, 0.1)',
  },
  14: {
    hex: '#FF7F50',
    background: 'rgba(255, 127, 80, 0.1)',
  },
  15: {
    hex: '#40E0D0',
    background: 'rgba(64, 224, 208, 0.1)',
  },
  16: {
    hex: '#EE82EE',
    background: 'rgba(238, 130, 238, 0.1)',
  },
  17: {
    hex: '#87CEEB',
    background: 'rgba(135, 206, 235, 0.1)',
  },
  18: {
    hex: '#7373ca',
    background: 'rgb(115 115 202 / 0.1)',
  },
  19: {
    hex: '#7FFF00',
    background: 'rgba(127, 255, 0, 0.1)',
  },
  20: {
    hex: '#3EB489',
    background: 'rgba(62, 180, 137, 0.1)',
  },
  21: {
    hex: '#FF69B4',
    background: 'rgba(255, 105, 180, 0.1)',
  },
  22: {
    hex: '#00CED1',
    background: 'rgba(0, 206, 209, 0.1)',
  },
  23: {
    hex: '#800000',
    background: 'rgba(128, 0, 0, 0.1)',
  },
  24: {
    hex: '#8e8ee1',
    background: 'rgba(204, 204, 255, 0.1)',
  },
  26: {
    hex: '#808000',
    background: 'rgba(128, 128, 0, 0.1)',
  },
  27: {
    hex: '#DC143C',
    background: 'rgba(220, 20, 60, 0.1)',
  },
  28: {
    hex: '#6495ED',
    background: 'rgba(100, 149, 237, 0.1)',
  },
  29: {
    hex: '#6422ED',
    background: 'rgba(#6422ED, 0.1)',
  },
  30: {
    hex: '#6eeeED',
    background: 'rgba(#6eeeED, 0.1)',
  },
  31: {
    hex: '#6495E1',
    background: 'rgba(#6495E1, 0.1)',
  },
  32: {
    hex: '#649456',
    background: 'rgba(#649456, 0.1)',
  },
  33: {
    hex: '#6cc000',
    background: 'rgba(#6cc000, 0.1)',
  },
  34: {
    hex: '#ddd5ED',
    background: 'rgba(#ddd5ED, 0.1)',
  },
  35: {
    hex: '#64aaaD',
    background: 'rgba(#64aaaD, 0.1)',
  },
  // // // 36: {
  // // //   hex: '#6aaaED',
  // // //   background: 'rgba(100, 149, 237, 0.1)',
  // // // },
}

const MODALS = [
  {
    id: 'schedule_session',
    open: false,
  },
  {
    id: 'event',
    open: false,
  },
  {
    id: 'smart_schedule',
    open: false,
  },
  {
    id: 'custom_iteration',
    open: false,
  },
  {
    id: 'remove',
    open: false,
  },
  {
    id: 'group_session',
    open: false,
  },
]

const WORKSPACES = {
  TAKI: {
    name: 'taki',
    thumbnail: takiIcon,
    label: 'Taki Academy',
  },
  OSTEDHY: {
    name: 'ostedhy',
    thumbnail: ostedhyIcon,
    label: 'Ostedhy',
  },
}

const SIDEBAR_STYLES = {
  open: 'main_sidebar__open',
  mini: 'main_sidebar__mini',
  hidden: 'main_sidebar__hidden',
}

const NAVIGATION_LINKS = [
  {
    label: 'Dashboard',
    iconName: 'dashboardIcon',
    type: 'bouton',
    path: '/',
  },
  {
    label: 'My Calendar',
    iconName: 'calendarIcon',
    type: 'route-link',
    path: '/',
  },
  // {
  //   label: 'Teachers',
  //   iconName: 'teachersIcon',
  //   type: 'route-link',
  //   path: '/teachers',
  // },
  // {
  //   label: 'Classes',
  //   iconName: 'classesIcon',
  //   type: 'route-link',
  //   path: '/classes',
  // },
  // {
  //   label: 'Subjects',
  //   iconName: 'subjectsIcon',
  //   type: 'route-link',
  //   path: '/subjects',
  // },
  // {
  //   label: 'Offers',
  //   iconName: 'offersIcon',
  //   type: 'route-link',
  //   path: '/offers',
  // },
  // {
  //   label: 'Levels',
  //   iconName: 'levelsIcon',
  //   type: 'route-link',
  //   path: '/levels',
  // },
  {
    label: 'Streaming Tools',
    iconName: 'streamingToolsIcon',
    type: 'route-link',
    path: '/streaming-tools',
  },
  {
    label: 'Settings',
    iconName: 'settingsIcon',
    type: 'bouton',
  },
]

const CLIENT_ONE_URLS = {
  events: () => `/api/admin/events`,
  duplicateEvent: () => `/api/admin/calendar-events/duplicate`,
  classes: () =>
    '/api/admin/divisions?page=1&perPage=100&search=isPublic:1searchJoin=and',
  offers: () => '/api/admin/groups?search=active:1',
  instructors: () => '/api/admin/instructors',
  webinars: () => '/api/admin/webinars',
  subjects: () => '/api/admin/subjects',
  groupSessions: () => '/api/admin/traineeships',
  levels: () => '/api/admin/student-levels',
  media: () => null,
}

const CLIENT_TWO_URLS = {
  events: (start, end) => {
    return `/api/sessions?query={"and":[{"start_date":{"between":[${formatIntervalDateToGmt(
      start,
      end,
    )}]}}]}&include=[{"model":"GroupSession", "paranoid": false, "include":[{"model":"User", "as":"teachers"},{"model":"StudentLevel"},{"model":"Subject","include":[{"model":"Class"}] } ]},{"model":"SessionType"}]`
  },
  duplicateEvent: () => '/api/sessions/duplicate',
  classes: () => '/api/classes',
  instructors: () => '/api/users/teachers',
  subjects: () => `/api/subjects`,
  subjectsByTeacher: () => '/api/subjects/teacher',
  levels: () => '/api/user-categories',
  streamingTools: () => '/api/session-types',
  media: () => process.env.REACT_APP_OSTEDHY_UPLOAD,
  updateEvent: () => `/api/sessions/edit-session`,
  groupSessions: () => '/api/group-sessions',
  webinars: () => '/api/webinar-accounts',
  sessionTypes: () => '/api/session-types',
}

const INTERVAL_OPTIONS = [
  {
    label: 'Day',
    value: 1,
  },
  {
    label: 'Week',
    value: 2,
  },
  {
    label: 'Month',
    value: 3,
  },
]

const DAYS_LABELS = [
  {
    id: 1,
    label: 'MON',
  },
  {
    id: 2,
    label: 'TUE',
  },
  {
    id: 3,
    label: 'WED',
  },
  {
    id: 4,
    label: 'THU',
  },
  {
    id: 5,
    label: 'FRI',
  },
  {
    id: 6,
    label: 'SAT',
  },
  {
    id: 0,
    label: 'SUN',
  },
]

const INPUT_TYPES = {
  date: 'date',
  customIteration: 'custom-iteration',
  asyncSelect: 'async-select',
  groupSession: 'group-session',
  number: 'number',
  asyncMultiSelect: 'async-multi-select',
  time: 'time',
  checkbox: 'checkbox',
}
const WEEKS_LABELS = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
}

export {
  MODALS,
  WORKSPACES,
  SIDEBAR_STYLES,
  NAVIGATION_LINKS,
  CLIENT_ONE_URLS,
  CLIENT_TWO_URLS,
  COLORS,
  INTERVAL_OPTIONS,
  DAYS_LABELS,
  INPUT_TYPES,
  WEEKS_LABELS,
}
