import { createSlice } from '@reduxjs/toolkit'
import { WORKSPACES } from '../../constants'

const currentWorkspace = localStorage.getItem('currentWorkspace')
if (!currentWorkspace)
  localStorage.setItem('currentWorkspace', WORKSPACES.TAKI.name)

const initialState = {
  currentWorkspace:
    localStorage.getItem('currentWorkspace') === WORKSPACES.TAKI.name
      ? WORKSPACES.TAKI
      : WORKSPACES.OSTEDHY,
  workspaces: [WORKSPACES.TAKI, WORKSPACES.OSTEDHY],
}

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    setWorkspace: (state, action) => {
      state.currentWorkspace = action.payload
    },
    resetWorkspaceState: () => initialState,
  },
})
export const getWorkspaces = state => state.workspaces.workspaces
export const getCurrentWorkspace = state => state.workspaces.currentWorkspace
export const { setWorkspace, resetWorkspaceState } = workspacesSlice.actions
export default workspacesSlice.reducer
