import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { axiosInstance } from '../utils/Connections/AxiosInstance'
import { toast } from 'react-toastify'

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  accessToken: null,
  user: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { user, access_token } = action.payload
      const username = `${user.first_name} ${user.last_name}`
      const role = user.roles
      return {
        ...state,
        isAuthenticated: true,
        user: {
          username,
          role,
          email: user.email,
          id: user.id,
          permissions:
            user.roless?.flatMap(r => r.permissions.map(p => p.name)) || [],
        },
        isInitialized: true,
        accessToken: access_token,
      }
    }
    case 'RESET': {
      return { ...state, ...initialAuthState, isInitialized: true }
    }
    case 'LOGIN': {
      const { user, access_token } = action.payload
      const username = `${user.first_name} ${user.last_name}`
      const role = user.roles
      return {
        ...state,
        isAuthenticated: true,
        user: {
          username,
          role,
          email: user.email,
          id: user.id,
          permissions:
            user.roless?.flatMap(r => r.permissions.map(p => p.name)) || [],
        },
        accessToken: access_token,
      }
    }
    default: {
      return { ...state }
    }
  }
}

const refreshToken = async () => {
  try {
    const result = await axiosInstance.post('/auth/token', null, {
      params: {
        grant_type: 'refresh_token',
        refresh_token: localStorage.getItem('refreshToken') || '',
      },
    })
    return result
  } catch (error) {
    console.error(error)
  }
}

const OstedhyAuthContext = createContext({
  ...initialAuthState,
  login: (email, password) => Promise.resolve(),
  logout: () => Promise.resolve(),
  getMe: () => Promise.resolve(),
})

export const OstedhyAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)
  const [requestStatus, setRequestStatus] = useState('idle')

  const updateRequestStatus = status => {
    setRequestStatus(status)
  }

  const login = async (email, password) => {
    updateRequestStatus('loading')
    try {
      const response = await axiosInstance.post('/auth/admins/login', {
        email,
        password,
      })

      const { user, access_token, refresh_token } = response.data
      localStorage.setItem('accessToken', access_token)
      localStorage.setItem('refreshToken', refresh_token)
      if (response.status === 201) {
        toast.success('Login successful', {
          autoClose: 1500,
        })
        updateRequestStatus('success')
        dispatch({ type: 'LOGIN', payload: { user, access_token } })
      }
    } catch (error) {
      toast.error('Invalid credentials', {
        autoClose: 1500,
      })
      updateRequestStatus('error')
      dispatch({ type: 'RESET' })
    }
  }

  // Get user information
  const getMe = async () => {
    try {
      const response = await axiosInstance.get(`/api/profile/me`)
      const user = response.data.payload

      const access_token = localStorage.getItem('accessToken')
      dispatch({
        type: 'INITIALIZE',
        payload: {
          user,
          access_token,
        },
      })
    } catch (error) {
      toast.error('Something went wrong', {
        autoClose: 1500,
      })
      dispatch({ type: 'RESET' })
    }
  }

  const logout = async () => {
    try {
      await axiosInstance.post('/auth/logout')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      dispatch({ type: 'RESET' })
    } catch (error) {
      toast.error('Something went wrong', {
        autoClose: 1500,
      })
      dispatch({ type: 'RESET' })
    }
  }

  useEffect(() => {
    const refresh = async () => {
      try {
        const result = await refreshToken()

        if (result?.status === 201) {
          localStorage.setItem('accessToken', result?.data?.access_token)
          dispatch({
            type: 'INITIALIZE',
            payload: {
              user: result?.data?.user,
              access_token: result?.data?.access_token,
            },
          })
        } else {
          dispatch({ type: 'RESET' })
        }
      } catch (error) {
        toast.error('Something went wrong', {
          autoClose: 1500,
        })
      }
    }

    refresh()
  }, [])

  return (
    <OstedhyAuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        getMe,
        updateRequestStatus,
        requestStatus,
      }}>
      {children}
    </OstedhyAuthContext.Provider>
  )
}

export const useOstedhyAuthContext = () => useContext(OstedhyAuthContext)
