import clsx from 'clsx'
import React, { forwardRef } from 'react'

const CustomInput = forwardRef(
  (
    {
      name,
      icon,
      type = 'text',
      onChange,
      value,
      error = { status: false, message: '' },
      register,
      required,
      ...inputProps
    },
    ref,
  ) => {
    return (
      <>
        <div
          className={clsx(
            'custom-input',
            error.status && 'custom-input-error',
          )}>
          {icon && <span className='custom-input__icon'>{icon}</span>}
          <span className='custom-input__divider'></span>
          <input
            autoComplete='off'
            ref={ref}
            className='custom-input__input'
            type={type}
            {...register(name, { required })}
            {...inputProps}
          />
          {error.status ? (
            <p className='custom-input__text-error'>{error.message}</p>
          ) : null}
        </div>
      </>
    )
  },
)

export default CustomInput
