import { createSlice } from '@reduxjs/toolkit'

const initialiseSidebar = () => {
  if (typeof Storage !== 'undefined') {
    const status = localStorage.getItem('sidebarStyle')
    if (status) {
      //   return JSON.parse(status)
      return status
    } else {
      localStorage.setItem('sidebarStyle', 'open')
    }
  }
  return 'open'
}

const initialState = {
  sidebarStyle: initialiseSidebar(),
}

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    toggleSidebarStatus: (state, action) => {
      const status = action.payload || 'open'
      state.sidebarStyle = status
      if (typeof Storage !== 'undefined') {
        localStorage.setItem('sidebarStyle', status)
      }
    },
  },
})

export const { toggleSidebarStatus } = settingSlice.actions

export default settingSlice.reducer
