import React from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import { Dialog, Divider } from '@mui/material'
import { openModal, setIsEditing } from '../../../store/slices/modalsSlice'
import { Button } from '../../Form'
import List from './components/List'
import Label from './components/Label'
import Offer from './components/Offer'
import Teacher from './components/Teacher'
import Category from './components/Category'
import userIcon from '../../../assets/icons/modal-event-admin.svg'
import classIcon from '../../../assets/icons/modal-event-class.svg'
import levelIcon from '../../../assets/icons/event-modal-subject.svg'
import subjectIcon from '../../../assets/icons/event-modal-level.svg'
import StreamingTool from '../../../assets/icons/modal-streaming-tool.svg'
import CustomCheckbox from '../../Checkbox/Checkbox'
import { deleteOneEvent } from '../../../store/apis/eventApi'
import { Can } from '../../../context/AbilityContext'
import { Popover } from '../../Popover'
import { isOstedhy, isTakiacademy } from '../../../utils/workspaceChecker'
import CloseIcon from '../../SvgIcons/CloseIcon/CloseIcon'

const Event = ({ id, open, handleClose, data }) => {
  const dispatch = useDispatch()

  const startDate = dayjs(data?.start_date).format('ddd, D MMMM, YYYY HH:mm')
  const endDate = dayjs(data?.end_date).format('HH:mm')

  const handleDeleteEvent = async params => {
    const payload = {
      ...params,
      id: data.id,
    }
    return dispatch(deleteOneEvent(isOstedhy ? data.id : payload)).unwrap()
  }

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id)
        }
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='ta-modal add-video-modal '>
      <div className='event-modal'>
        <header className='event-modal__header'>
          <div className='desc'>
            <p className='desc__title'>{data?.name}</p>
            <p className='desc__date'>
              {startDate} - {endDate} ({data?.duration} hour)
            </p>
          </div>
          <CloseIcon onClick={() => handleClose(id)} />
          {/* <span className='close-btn' onClick={() => handleClose(id)}></span> */}
        </header>
        <div className='event-modal__details'>
          <Teacher data={data?.teacher} />
          {data?.offers?.length > 0 ? (
            <Popover
              content={
                data?.offers?.length > 1 &&
                data?.offers?.map(offer => (
                  <Offer key={offer.id} offer={offer} />
                ))
              }>
              {data?.offers && data?.offers ? (
                <Offer
                  offer={data?.offers[0]}
                  classes={'offer'}
                  multi={data?.offers?.length > 1}
                />
              ) : null}
            </Popover>
          ) : null}
        </div>
        <Divider />
        <div className='event-modal__about'>
          <p className='session-info'>Session Information:</p>
          <Category label={'Classes'} items={data?.classes} icon={classIcon} />
          <Category
            label={'Subjects'}
            items={[data?.subjects[0]]}
            icon={subjectIcon}
          />
          <div className='category'>
            <Label icon={levelIcon} label='Levels' />
            <List
              items={
                data?.levels?.length === 0
                  ? [{ id: nanoid(4), name: 'All' }]
                  : data?.levels
              }
            />
          </div>
          {isTakiacademy && (
            <Category
              label={'Created By'}
              items={[data?.admin]}
              icon={userIcon}
            />
          )}
          {data?.webinar_account && (
            <Category
              label={'Streaming account'}
              items={[
                {
                  id: data?.webinar_account.id,
                  name: data?.webinar_account.email,
                },
              ]}
              icon={StreamingTool}
            />
          )}
          <CustomCheckbox
            label='Draft'
            checked={data?.is_draft}
            cls='draft-check'
          />
        </div>
        <Can i='manage' a='event'>
          <footer>
            <p className='text'>Do you want to?</p>
            <div className='btns-container'>
              <Button
                cls='delete'
                onClick={() => {
                  handleClose(id)
                  dispatch(
                    openModal('remove', {
                      type: 'Event',
                      action: params => handleDeleteEvent(params),
                    }),
                  )
                }}>
                Delete
              </Button>
              <Button
                cls='reschedule'
                onClick={() => {
                  handleClose(id)
                  dispatch(setIsEditing(true))
                  dispatch(openModal('schedule_session', data))
                }}>
                Reschedule
              </Button>
              {isTakiacademy && (
                <Button
                  cls='duplicate'
                  onClick={() => {
                    handleClose(id)
                    handleClose('schedule_session')
                    dispatch(openModal('custom_iteration', data))
                  }}>
                  Duplicate
                </Button>
              )}
            </div>
          </footer>
        </Can>
      </div>
    </Dialog>
  )
}

export default Event
