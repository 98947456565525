import React from 'react'

const Loading = () => {
  return (
    <div className='loading'>
      <div className='loadingio-spinner-dual-ring-ne4sspbrfmf'>
        <div className='ldio-k1xqovo44b'>
          <div></div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
      <style type='text/css'></style>
    </div>
  )
}

export default Loading
