import { WORKSPACES } from '../constants'

function extractOstedhyData(apiEvent) {
  const {
    name,
    start_date,
    end_date,
    id,
    session_type_id,
    webinarAccounts,
    recording_price,
    price,
    groupSessions,
    sessionTypes,
  } = apiEvent
  const { teachers, StudentLevels, subjects } = groupSessions
  const classes = subjects?.map(sub => sub.classes)

  const extractedData = {
    name,
    start_date,
    end_date,
    id,
    session_type_id,
    webinarAccounts,
    groupSessions,
    recording_price,
    price,
    classes,
    sessionTypes,
    teacher: teachers,
    levels: [StudentLevels],
    subjects,
  }

  return extractedData
}
function extractTakiData(apiEvent) {
  const {
    start_date,
    end_date,
    id,
    content: {
      instructor,
      name,
      content_student_levels,
      group_contents,
      is_draft,
      active,
      creator_admin,
    },
    subject_events,
    webinar_account,
    duration,
    url,
    reason,
    canceled,
    is_big_zoom_event,
    traineeship,
  } = apiEvent
  const classes = subject_events?.map(sub => sub.subject.division)

  const offers = group_contents.map(offer => ({ ...offer.groups }))

  const levels =
    content_student_levels.map(level => ({
      ...level?.student_level,
    })).length > 0
      ? content_student_levels.map(level => ({
          ...level?.student_level,
        }))
      : content_student_levels.map(level => ({
          ...level?.student_level,
        }))

  const extractedData = {
    name,
    start_date,
    end_date,
    webinar_account,
    id,
    offers,
    classes,
    duration,
    url,
    is_big_zoom_event,
    is_draft,
    active,
    canceled,
    admin: { name: creator_admin?.username, id: creator_admin?.id },
    reason,
    teacher: { ...instructor, first_name: instructor.name },
    levels,
    subjects: subject_events.map(sub => sub.subject),
    traineeship,
  }

  return extractedData
}

const currentWorkspace = localStorage.getItem('currentWorkspace')

let extractEventData =
  currentWorkspace === WORKSPACES.TAKI.name
    ? extractTakiData
    : extractOstedhyData

export default extractEventData
