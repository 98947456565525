import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const Popover = ({ content, children }) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const popoverRef = useRef(null);

  const handleMouseEnter = () => {
    if (content) {
      setPopoverVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setPopoverVisible(false);
  };

  useOnClickOutside(popoverRef, () => handleMouseLeave());

  if (!content) {
    return <div>{children}</div>;
  }

  return (
    <div
      className={`popover ${isPopoverVisible ? 'popover--visible' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={popoverRef}
    >
      <div className="popover__trigger">{children}</div>
      <div
        className={`popover__content ${
          isPopoverVisible ? 'popover__content--visible' : ''
        }`}
      >
        {content}
      </div>
    </div>
  );
};

Popover.propTypes = {
  content: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default Popover;
