import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getModals, closeModal } from '../../store/slices/modalsSlice'

import {
  Event,
  ScheduleSession,
  DeletePopup,
  CustomIteration,
  GroupSession,
} from '../Modals'

const ModalsProvider = () => {
  const modals = useSelector(getModals)
  const dispatch = useDispatch()
  const modalState = (id, key) => {
    const res = modals.find(modal => modal.id === id)
    return res[key]
  }
  const handleClose = id => {
    dispatch(closeModal(id))
  }
  return (
    <>
      {modalState('schedule_session', 'open') && (
        <ScheduleSession
          id='schedule_session'
          open={modalState('schedule_session', 'open')}
          data={modalState('schedule_session', 'data')}
          handleClose={handleClose}
        />
      )}
      <Event
        id='event'
        open={modalState('event', 'open')}
        data={modalState('event', 'data')}
        handleClose={handleClose}
      />
      <CustomIteration
        id='custom_iteration'
        open={modalState('custom_iteration', 'open')}
        data={modalState('custom_iteration', 'data')}
        handleClose={handleClose}
      />
      <DeletePopup
        id='remove'
        open={modalState('remove', 'open')}
        data={modalState('remove', 'data')}
        handleClose={handleClose}
      />
      <GroupSession
        id='group_session'
        open={modalState('group_session', 'open')}
        data={modalState('group_session', 'data')}
        handleClose={handleClose}
      />
    </>
  )
}

export default ModalsProvider
