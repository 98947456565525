import { createContext } from 'react'
import { createContextualCan } from '@casl/react'
import ability from '../utils/ability'
import { useAuthContext } from './AuthContext'
import { useSelector } from 'react-redux'
import { getCurrentWorkspace } from '../store/slices/workspacesSlice'

export const AbilityContext = createContext()
export const Can = createContextualCan(AbilityContext.Consumer)

export default function AbilityProvider({ children }) {
  const currentWorkspace = useSelector(getCurrentWorkspace)
  const { isAuthenticated, user } = useAuthContext[currentWorkspace.name]()

  return (
    <AbilityContext.Provider value={ability(isAuthenticated, user)}>
      {children}
    </AbilityContext.Provider>
  )
}
