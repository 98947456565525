import React from 'react'

const CheckedCheckboxIcon = ({ checkboxColor = '#00AAF9' }) => {
  return (
    <svg
      width='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='20' height='20' rx='6' fill={checkboxColor} />
      <g clipPath='url(#clip0_153_2917)'>
        <path
          d='M7.87459 14.3313C7.53396 14.3315 7.20728 14.1961 6.96662 13.955L4.22153 11.2109C3.92616 10.9155 3.92616 10.4365 4.22153 10.141C4.517 9.84567 4.99595 9.84567 5.29142 10.141L7.87459 12.7242L14.7086 5.89023C15.004 5.59486 15.483 5.59486 15.7785 5.89023C16.0738 6.1857 16.0738 6.66465 15.7785 6.96012L8.78256 13.955C8.5419 14.1961 8.21523 14.3315 7.87459 14.3313Z'
          fill='white'
        />
      </g>
    </svg>
  )
}

export default CheckedCheckboxIcon
