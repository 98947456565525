import React, { useEffect, useState } from 'react'
import SelectInput from '../SelectInput/SelectInput'
import { pluralize } from '../../../utils/pluralize'
import { INTERVAL_OPTIONS } from '../../../constants'
import clsx from 'clsx'

const RepeatInput = ({ onChange }) => {
  const [repetitions, setRepetitions] = useState(1)
  const [options, setOptions] = useState(INTERVAL_OPTIONS)
  const [selectedInterval, setSelectedInterval] = useState(INTERVAL_OPTIONS[0])
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  useEffect(() => {
    setOptions(prevOptions =>
      prevOptions.map(option => {
        return { ...option, label: pluralize(option.label, repetitions) }
      }),
    )
    if (selectedInterval) {
      setSelectedInterval(prevSelectedInterval => {
        return {
          ...prevSelectedInterval,
          label: pluralize(prevSelectedInterval.label, repetitions),
        }
      })
    }
  }, [repetitions])

  const isWeekly = selectedInterval?.value === 2
  const isMonthly = selectedInterval?.value === 3
  const maxRepetitions = isWeekly ? 12 : isMonthly ? 3 : 90

  const handleSelect = option => {
    setRepetitions(1)
    setSelectedInterval(option)
    onChange({
      repetitions,
      selectedInterval: option,
    })
    setError(false)
    setErrorMessage(false)
  }
  const handleChange = e => {
    const value = e.target.value * 1
    const isNotNumberValue = typeof value !== 'number'
    const negativeValue = value === 0 || value < 1
    const outOfRangeValue = value > maxRepetitions

    if (outOfRangeValue) {
      setError(true)
      setErrorMessage(
        `Number must be lesser or equal to ${maxRepetitions} in ${selectedInterval.label}`,
      )
    } else if (isNotNumberValue) {
    } else if (negativeValue) {
      setError(true)
      setErrorMessage(`Please enter a valid value`)
    } else {
      setError(false)
    }
    setRepetitions(value)
    onChange({
      repetitions: value,
      selectedInterval,
    })
  }

  return (
    <div className='repeat-input'>
      <label htmlFor='repetitions' className='repeat-input__label'>
        Repeat Every
      </label>
      <input
        type='number'
        id='repetitions'
        className={clsx('repeat-input__reps', error && 'error')}
        defaultValue={repetitions}
        value={repetitions}
        onChange={handleChange}
        max={maxRepetitions}
      />
      <SelectInput
        onClick={handleSelect}
        options={options}
        cls='repeat-input__select'
        defaultValue={selectedInterval}
      />
      {error && <p className='error-text'>{errorMessage}</p>}
    </div>
  )
}

export default RepeatInput
