import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import MainSidebar from '../../components/MainSidebar'
import MainHeader from '../../components/MainHeader'
import AuthGuard from '../../components/AuthGuard'

const MainLayout = () => {
  return (
    <AuthGuard>
      <div className='main_layout'>
        <MainSidebar />
        <div className='main_layout__container'>
          <MainHeader />
          <Suspense fallback={<div className='dummy'>Loading...</div>}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </AuthGuard>
  )
}

export default MainLayout
