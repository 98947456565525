import { TakiAuthProvider } from './TakiAuthContext'
import { OstedhyAuthProvider } from './OstedhyAuthContext'
import { useSelector } from 'react-redux'
import { WORKSPACES } from '../constants'
import { getCurrentWorkspace } from '../store/slices/workspacesSlice'

const AuthProvider = ({ children }) => {
  let currentWorkspace = useSelector(getCurrentWorkspace)

  return (
    <>
      {currentWorkspace.name === WORKSPACES.TAKI.name ? (
        <TakiAuthProvider>{children}</TakiAuthProvider>
      ) : (
        <OstedhyAuthProvider>{children}</OstedhyAuthProvider>
      )}
    </>
  )
}

export default AuthProvider
