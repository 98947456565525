import clsx from 'clsx'
import React from 'react'

const Button = ({ children, onClick, cls, ...props }) => {
  return (
    <button className={clsx('button', cls)} onClick={onClick} {...props}>
      {children}
    </button>
  )
}

export default Button
