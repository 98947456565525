import { RRule, datetime } from 'rrule'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  weekStart: 0,
})

dayjs.extend(customParseFormat)

const weekdays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']

export const getWeekNumber = date => {
  const initialDate = dayjs(date)
  const firstDayOfMonth = dayjs(initialDate).set('day', 1)
  const dayOfWeek = firstDayOfMonth.day()
  const daysInFirstWeek = (8 - dayOfWeek) % 7

  const currentWeek = Math.ceil((initialDate.date() - daysInFirstWeek) / 7)
  return currentWeek === 5 ? 4 : currentWeek
}

export const getDatetimeObject = formattedDate => {
  return datetime(
    formattedDate.year(),
    formattedDate.month() + 1,
    formattedDate.date(),
    formattedDate.hour(),
    formattedDate.minute(),
  )
}

export const getDailyDates = (currentDate, interval, endDateTime) => {
  const startAfter = dayjs(currentDate).subtract(1, 'hour')

  const formatCurrentDate = dayjs(startAfter, {
    format: 'YYYY, M, D, H, m',
  })

  const rule = new RRule({
    freq: RRule.DAILY,
    interval: interval,
    dtstart: getDatetimeObject(formatCurrentDate),
    tzid: 'Africa/Tunis',
    until: new Date(endDateTime),
  })
  return rule
    .between(
      getDatetimeObject(formatCurrentDate),
      new Date(dayjs(endDateTime).format('YYYY-MM-DD HH:mm:ss')),
    )
    .map(date => dayjs(date).format())
}

export const getWeeklyDates = (
  selectedDays,
  intervalReps,
  currentDate,
  endDateTime,
) => {
  const weekdays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
  const targetWeekdays = selectedDays.map(weekDay => weekdays[weekDay])
  const formatCurrentDate = dayjs(currentDate, {
    format: 'YYYY, M, D, H, m',
  }).subtract(1, 'hour')

  const rule = new RRule({
    freq: RRule.WEEKLY,
    interval: intervalReps,
    byweekday: targetWeekdays,
    dtstart: getDatetimeObject(formatCurrentDate),
    until: new Date(endDateTime),
  })
  return rule
    .between(
      getDatetimeObject(formatCurrentDate),
      new Date(dayjs(endDateTime).format()),
    )
    .map(date => dayjs(date).format('YYYY-MM-DD HH:mm:ss'))
}
export function getMonthlyDates(
  currentDate,
  endDate,
  interval,
  isZoomEvent,
  isExactDateEachMonth,
) {
  const initialDate = dayjs(currentDate)
  const targetWeekNumber = getWeekNumber(initialDate)
  const weekday = initialDate.day()
  const monthlyDay = initialDate.date()
  const targetWeekday = RRule[weekdays[weekday]]

  const formatCurrentDate = dayjs(initialDate, {
    format: 'YYYY, M, D, H, m',
  }).subtract(1, 'hour')

  const rruleOptions = {
    freq: RRule.MONTHLY,
    wkst: RRule.SU,
    dtstart: getDatetimeObject(formatCurrentDate),
    until: new Date(endDate),
    interval: interval || 1,
    bysetpos: isExactDateEachMonth ? targetWeekNumber : undefined,
    byweekday: isExactDateEachMonth ? targetWeekday : undefined,
    tzid: 'Africa/Tunis',
  }
  const rule = new RRule(rruleOptions)

  const formattedRecurringDates = rule
    .between(getDatetimeObject(formatCurrentDate), new Date(endDate))
    .map(date => dayjs(date).format('YYYY-MM-DD HH:mm:ss'))
  return {
    dates: formattedRecurringDates,
    monthlyWeek: targetWeekNumber,
    monthlyWeekDay: weekday + 1,
    monthlyDay,
  }
}
