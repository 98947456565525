import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createOne,
  deleteEvents,
  postUpdateOne,
  specialDelete,
  updateOne,
} from '../../utils/Connections/AxiosInstance'
import { isOstedhy, isTakiacademy } from '../../utils/workspaceChecker'
import { formatIntervalDateToISO } from '../../utils/customDateFormats'
import { getData } from '../../utils/Connections/AxiosInstance'
import { urls } from '../../utils/Connections/AxiosInstance'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

const getAllTakiEvents = params => {
  return getData(urls.events(), {
    search: `startDate:${formatIntervalDateToISO(params.start, params.end)}${
      params.search ? `${params.search}` : ''
    }`,
    orderBy: 'id',
    sortedBy: 'desc',
    searchJoin: 'and',
    limit: 50000,
    perPage: 1000,
  })
}
const getAllOstedhyEvents = params =>
  getData(urls.events(params.start, params.end))

const getAllEvents = isTakiacademy ? getAllTakiEvents : getAllOstedhyEvents

export const createOneEvent = createAsyncThunk(
  'events/createOneEvent',
  async data => {
    try {
      const response = await createOne(urls.events(), data)
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
)
export const duplicateEvent = createAsyncThunk(
  'events/duplicate',
  async data => {
    try {
      const response = await createOne(urls.duplicateEvent(), data)
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const updateOneEvent = createAsyncThunk(
  'events/updateOneEvent',
  async ({ id, data }) => {
    try {
      const updateMethod = isOstedhy
        ? updateOne.bind(null, urls.events())
        : postUpdateOne.bind(null, urls.events())
      const response = await updateMethod(id, data)
      return response?.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const fetchEventsAsync = createAsyncThunk(
  'events/getEvents',
  async (params = {}) => {
    try {
      const date = params.date
      let start = date
      let end = null
      const isWeekView = params.calendarView === 'week'

      if (isWeekView) {
        start = dayjs(date).startOf('week').toString()
        end = dayjs(date).endOf('week').add(1, 'day').toString()
      }

      const { data } = await getAllEvents({
        start: start,
        end: end,
        search: params?.search || null,
      })
      return data.payload
    } catch (error) {
      toast.error(error.message, { autoClose: 300 })
    }
  },
)

export const deleteOneEvent = createAsyncThunk(
  'events/deleteOneEvent',
  async (id, { rejectWithValue }) => {
    try {
      const deleteEvent = isOstedhy ? specialDelete : deleteEvents
      const response = await deleteEvent(urls.events(), id)
      return response?.data
    } catch (error) {
      return rejectWithValue(error.response || error)

    }
  },
)
