import { INPUT_TYPES } from '.'

export const FILTERS = [
  //   {
  //     type: INPUT_TYPES.date,
  //     label: 'Date',
  //     id: 'date',
  //   },
  //   {
  //     type: INPUT_TYPES.time,
  //     label: 'Time',
  //     id: 'time',
  //   },
  {
    type: INPUT_TYPES.asyncMultiSelect,

    id: 'webinar',
    isMulti: false,
    optionDisplayAttribute: 'email',
    searchAttribute: 'email',
    placeholder: 'webinar',
  },
  {
    type: INPUT_TYPES.asyncMultiSelect,

    id: 'offerIds',
    optionDisplayAttribute: 'name',
    searchAttribute: 'name',
    placeholder: 'offers',
  },
  {
    type: INPUT_TYPES.asyncMultiSelect,

    isMulti: false,
    optionDisplayAttribute: 'name',
    id: 'subjectId',
    searchAttribute: 'name',
    placeholder: 'subject',
  },
  //   {
  //     type: INPUT_TYPES.asyncMultiSelect,
  //     label: 'Levels',
  //     id: 'levelIds',
  //     optionDisplayAttribute: 'name',
  //     searchAttribute: 'name',
  //   },
  {
    type: INPUT_TYPES.asyncMultiSelect,

    isMulti: false,
    id: 'teacherId',
    optionDisplayAttribute: 'name,last_name',
    searchAttribute: 'name',
    searchJoin: 'or',
    placeholder: 'teacher',
  },

  //   {
  //     type: INPUT_TYPES.checkbox,
  //     label: 'Draft',
  //     id: 'is_draft',
  //   },
  //   {
  //     type: INPUT_TYPES.checkbox,
  //     label: 'Active',
  //     id: 'active',
  //   },
]
