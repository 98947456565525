import dayjs from 'dayjs'

const FORMAT = 'ddd, DD MMM YYYY HH:mm:ss [GMT]'

export const gmtDateTimeFormatStart = day =>
  dayjs(day).startOf('day').format(FORMAT)

export const gmtDateTimeFormatEnd = day =>
  dayjs(day).endOf('day').format(FORMAT)

export const formatIntervalDateToGmt = (start, end) =>
  `"${gmtDateTimeFormatStart(start)}","${gmtDateTimeFormatEnd(end || start)}"`

export const formatIntervalDateToISO = (start, end) => {
  const noEndTime = !end
  return `${dayjs(start).startOf('day').format('YYYY-MM-DD')},${dayjs(
    end || start,
  )
    .add(noEndTime ? 1 : 0, 'day')
    .format('YYYY-MM-DD')}`
}

export const hoursMinutesFormat = date => dayjs(date).format('HH:mm')

export const calculateTimeDifference = (startDate, endDate) => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)
  const diff = end.diff(start, 'hour', true)
  return diff
}

export const addAnHourToDate = (date, time) =>
  `${dayjs(date)
    .add(dayjs(time).hour(), 'hour')
    .add(dayjs(time).minute(), 'minute')
    .format('YYYY-MM-DDTHH:mm:ss.000Z')}`

export const getLocalISOString = date =>
  `${dayjs(date).format('YYYY-MM-DDTHH:mm:ss.000Z')}`
