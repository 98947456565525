import React from 'react'
import { urls } from '../../../../utils/Connections/AxiosInstance'
import teacherImg from '../../../../assets/images/event-teacher-photo.png'

const Teacher = ({ data }) => {
  return (
    <div className='teacher'>
      <span>By</span>
      <img
        src={
          !!data?.photo_url ? `${urls.media()}/${data.photo_url}` : teacherImg
        }
        alt={data?.name}
        className='teacher__profile-pic'
      />
      <span className='teacher__name'>
        {data?.first_name + ' ' + data?.last_name}
      </span>
    </div>
  )
}

export default Teacher
