import { configureStore } from '@reduxjs/toolkit'
import settingReducer from './slices/settingSlice'
import memberReducer from './slices/memberSlice'
import userActivityReducer from './slices/userActivitySlice'
import calendarReducer from './slices/calendarSlice'
import modalsSlice from './slices/modalsSlice'
import classesSlice from './slices/classesSlice'
import workspacesReducer from './slices/workspacesSlice'
import contentReducer from './slices/contentSlice'
import eventReducer from './slices/eventSlice'
import streamingToolsReducer from './slices/streamingTools'
export const store = configureStore({
  reducer: {
    setting: settingReducer,
    member: memberReducer,
    userActivity: userActivityReducer,
    calendar: calendarReducer,
    modals: modalsSlice,
    classes: classesSlice,
    workspaces: workspacesReducer,
    content: contentReducer,
    event: eventReducer,
    streamingTool: streamingToolsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check
    }),
})
