import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as SelectArrow } from '../../../assets/icons/select-arrow.svg'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import clsx from 'clsx'

const SelectInput = ({
  id,
  label,
  defaultValue,
  onClick,
  options,
  cls,
  key,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue || null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const selectRef = useRef(null)

  useOnClickOutside(selectRef, () => setDropdownOpen(false))

  const handleOptionSelect = option => {
    setSelectedOption(option)
    setDropdownOpen(false)
    onClick(option)
  }

  useEffect(() => {
    setSelectedOption(defaultValue)
  }, [defaultValue])

  // useEffect(() => {
  //   setContainerHeight(() => {
  //     let height = 'auto'
  //     if (options?.length > 1 && dropdownOpen)
  //       height = `${45 * options.length + 70}px`
  //     return height
  //   })
  // }, [dropdownOpen])

  return (
    <div className={clsx('custom-dropdown', cls)} ref={selectRef} key={key}>
      <label htmlFor={id} className='custom-dropdown__label'>
        {label}
      </label>
      <div
        id={id}
        className='custom-dropdown__selected-option'
        onClick={() => setDropdownOpen(!dropdownOpen)}
        {...props}>
        {selectedOption ? selectedOption.label : 'Select'}
        <SelectArrow />
      </div>
      {dropdownOpen && (
        <ul className='custom-dropdown__dropdown-list'>
          {options.map(option => (
            <li key={option.value} onClick={() => handleOptionSelect(option)}>
              {!!option.imgLink && (
                <img src={option.imgLink} alt={option.value} className='icon' />
              )}
              <p>{option.label}</p>
              {!!option.status && <p className='status'>{option.status}</p>}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default SelectInput
