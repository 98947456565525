import dayjs from 'dayjs'

export const calculateRemainingHours = (startDate, endDate) => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)

  const durationInMilliseconds = end.diff(start)
  const durationInHours = durationInMilliseconds / (1000 * 60 * 60)

  const remainingHours = durationInHours % 24

  const randomDateAfterMidnight = end
    .startOf('day')
    .add(1, 'day')
    .add(remainingHours, 'hour')

  return randomDateAfterMidnight.$d
}
