import axios from 'axios'

const BASE_URL = process.env.REACT_APP_OSTEDHY_BASE_URL
const secondaryProtectedApi = axios.create({
  baseURL: BASE_URL,
  //timeout: 10000,
})

secondaryProtectedApi.interceptors.request.use(
  config => {
    config.headers['Authorization'] =
      'Bearer ' + localStorage.getItem('accessToken')
    return config
  },
  error => {
    Promise.reject(error)
  },
)
secondaryProtectedApi.interceptors.response.use(
  response => response,
  async error => {
    const previousRequest = error?.config
    if (
      error?.response?.status === 401 &&
      error?.response?.data === 'jwt expired' &&
      !previousRequest?.sent
    ) {
      previousRequest.sent = true
      const response = await secondaryProtectedApi.post(
        '/api/auth/token',
        null,
        {
          params: {
            grant_type: 'refresh_token',
            refresh_token: localStorage.getItem('/api/refreshToken'),
          },
        },
      )
      const newAccessToken = response.data.access_token
      localStorage.setItem('accessToken', newAccessToken)
      localStorage.setItem('refreshToken', response.data.refresh_token)
      previousRequest.headers['Authorization'] = 'Bearer ' + newAccessToken
      return secondaryProtectedApi(previousRequest)
    }
    return Promise.reject(error)
  },
)

export default secondaryProtectedApi
