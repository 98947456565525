import { createSlice } from '@reduxjs/toolkit'
import { COLORS } from '../../constants'
import { getClasses } from '../apis/classApi'
import { isTakiacademy } from '../../utils/workspaceChecker'

const initialState = {
  classes: [],
  status: false,
  classError: null,
  metaClasses: {},
}

const takiAcademySelectedClasses =
  JSON.parse(localStorage.getItem('takiSelectedClass')) || {}
const ostedhySelectedClasses =
  JSON.parse(localStorage.getItem('ostedhySelectedClass')) || {}

const localSelectedClasses = isTakiacademy
  ? takiAcademySelectedClasses
  : ostedhySelectedClasses

const localStorageKeyName = isTakiacademy
  ? 'takiSelectedClass'
  : 'ostedhySelectedClass'

export const classesSlice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    selectAll: (state, action) => {
      const allClassesSelected = state.classes.map(cls => {
        localSelectedClasses[cls.id] = action.payload
        const checkedClass = { ...cls, checked: action.payload }
        return checkedClass
      })
      localStorage.setItem(
        localStorageKeyName,
        JSON.stringify(localSelectedClasses),
      )
      state.classes = allClassesSelected
    },
    selectClassById: (state, action) => {
      const updatedClasses = state.classes.map(oneClass => {
        if (oneClass.id === action.payload.id) {
          localSelectedClasses[oneClass.id] = action.payload.value
          return { ...oneClass, checked: action.payload.value }
        }
        localSelectedClasses[oneClass.id] = oneClass.checked
        return oneClass
      })

      localStorage.setItem(
        localStorageKeyName,
        JSON.stringify(localSelectedClasses),
      )
      state.classes = updatedClasses
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getClasses.pending, state => {
        state.status = 'loading'
      })
      .addCase(getClasses.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.classes = action.payload?.map((cls, i) => ({
          checked: localSelectedClasses[cls.id] || false,
          color: COLORS[i + 1]?.hex,
          background: COLORS[i + 1]?.background,
          ...cls,
        }))
        state.metaClasses = action.payload.meta
      })
      .addCase(getClasses.rejected, (state, action) => {
        state.status = 'failed'
        state.classError = action.error.message
      })
  },
})

export const { selectAll, selectClassById } = classesSlice.actions

export const getStoreClasses = state => state.classes.classes
export const getClassesStatus = state => state.classes.status

export default classesSlice.reducer
