import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggleSidebarStatus } from '../../store/slices/settingSlice'
import useWindowSize from '../../hooks/useWindowSize'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import { ReactComponent as Typo } from '../../assets/icons/logo-typo.svg'
import { ReactComponent as ToggleIcon } from '../../assets/icons/sidebar-toggle-arrows.svg'
import NavigationLink from './NavigationLink'
import Workspace from '../Workspace/Workspace'
import { NAVIGATION_LINKS, SIDEBAR_STYLES } from '../../constants'

const MainSidebar = () => {
  const dispatch = useDispatch()
  const { sidebarStyle } = useSelector(state => state.setting)
  const { width } = useWindowSize()
  const sidebarEl = useRef()

  useOnClickOutside(
    sidebarEl,
    () => width <= 800 && dispatch(toggleSidebarStatus('hidden')),
  )

  useEffect(() => {
    sidebarEl.current.scrollTo(0, 0)
  }, [sidebarStyle])

  useEffect(() => {
    if (width <= 800 && sidebarStyle !== 'hidden')
      dispatch(toggleSidebarStatus('hidden'))

    if (width > 800 && sidebarStyle === 'hidden')
      dispatch(toggleSidebarStatus('open'))
  }, [width])

  const onClickAction = link => {
    if (['Settings', 'Dashboard'].includes(link.label) || width > 800) return
    dispatch(toggleSidebarStatus('hidden'))
  }

  return (
    <div
      className={clsx('main_sidebar', SIDEBAR_STYLES[sidebarStyle])}
      ref={sidebarEl}>
      <div className='main_sidebar__column'>
        <div className='main_sidebar__header'>
          <div className='main_sidebar__logo_container'>
            <Logo />
            <Typo className='main_sidebar__logo_container__typo' />
          </div>
          <div
            className='main_sidebar__toggle_btn'
            onClick={() =>
              dispatch(
                toggleSidebarStatus(
                  sidebarStyle === 'open'
                    ? width <= 800
                      ? 'hidden'
                      : 'mini'
                    : 'open',
                ),
              )
            }>
            <ToggleIcon />
          </div>
        </div>
        <div className='main_sidebar__navigation'>
          {NAVIGATION_LINKS.map(link => (
            <NavigationLink
              key={link.label}
              label={link.label}
              iconName={link.iconName}
              type={link.type}
              path={link.path}
              onClickAction={() => onClickAction(link)}
            />
          ))}
        </div>
        <div className='main_sidebar__favorites'>{/* favorites list */}</div>
      </div>

      <Workspace
        style={{
          bottom: '30px',
          margin: '0 auto 15px',
        }}
        hideElement={sidebarStyle === 'mini' ? true : false}
      />
    </div>
  )
}

export default MainSidebar
