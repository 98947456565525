import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import ViewTitle from '../../components/ViewTitle/ViewTitle'
import { LeftItems } from '../../components/LeftItems'

const CalendarLayout = () => {
  return (
    <div className='calendar_layout'>
      {/* <ViewTitle
        leftItems={<LeftItems title={''} />}
        style={{ height: '35px', paddingBottom: '10px' }}
      /> */}
      <Suspense fallback={<div className='dummy'>Loading...</div>}>
        <Outlet />
      </Suspense>
    </div>
  )
}

export default CalendarLayout
