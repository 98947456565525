import { TimeField } from '@mui/x-date-pickers'
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg'

const TimePicker = ({
  value,
  name,
  onChange,
  error = { state: false, message: '' },
}) => {
  return (
    <span style={{ position: 'relative' }}>
      <TimeField
        name={name}
        error={error.state}
        helperText={error.message}
        onChange={onChange}
        value={value}
        format='HH:mm'
        sx={{
          marginTop: '15px',
          marginLeft: '10px',
          width: '212px',
          maxWidth: '100%',
          '& p': {
            color: error.state && '#ed3863 !important',
            marginLeft: '0',
          },
          '& fieldset': {
            borderColor: error.state && '#ed3863 !important',
          },
        }}
      />
      <ClockIcon style={{ position: 'absolute', top: '33px', right: '10px' }} />
    </span>
  )
}

export default TimePicker
