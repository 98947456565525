import React from 'react'

const Item = ({ item, multi, ...props }) => {
  return (
    <div {...props} className='category__info' title={item?.name}>
      {item?.name} {multi && <span>...</span>}
    </div>
  )
}

export default Item
