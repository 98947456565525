import { createSlice } from '@reduxjs/toolkit'
import { MODALS } from '../../constants'

const initialState = {
  modals: MODALS,
  isEditing: false,
}

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setIsEditing: (state, action) => {
      state.isEditing = action.payload
    },
    openModal: (state, action) => {
      const { id, data } = action.payload
      const index = state.modals.findIndex(modal => modal.id === id)
      state.modals[index].open = true
      state.modals[index].data = data
    },
    closeModal: (state, action) => {
      const id = action.payload
      const index = state.modals.findIndex(modal => modal.id === id)
      state.modals[index].open = false
      state.isEditing = false
    },
  },
})

export const openModal = (id, data) => dispatch => {
  dispatch(modalsSlice.actions.openModal({ id, data }))
}

export const closeModal = id => dispatch => {
  dispatch(modalsSlice.actions.closeModal(id))
}

export const { setIsEditing } = modalsSlice.actions
export const getModals = state => state.modals.modals

export default modalsSlice.reducer
