const ViewTitle = ({ leftItems = null, rightItems = null, style }) => {
  return (
    <div className='view_title' style={style}>
      <div className='view_title__column'>{leftItems}</div>
      <div className='view_title__column'>{rightItems}</div>
    </div>
  )
}

export default ViewTitle
