import { createSlice, current } from '@reduxjs/toolkit'
// import { MEMBERS } from '../../constants'

// const initialiseMembers = () => {
//   return MEMBERS.map(member => {
//     return { ...member, checked: false }
//   })
// }

const initialState = {
  members: [],
}

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.members = current(state).members.map(member => {
        return { ...member, checked: action.payload }
      })
    },
    selectById: (state, action) => {
      state.members = current(state).members.map(member => {
        if (member.id === action.payload.id)
          return { ...member, checked: action.payload.value }
        return member
      })
    },
  },
})

export const { selectAll, selectById } = memberSlice.actions

export default memberSlice.reducer
