import clsx from 'clsx'
import { Divider } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toggleSidebarStatus } from '../../store/slices/settingSlice'
import useWindowSize from '../../hooks/useWindowSize'
import { ReactComponent as ToggleIcon } from '../../assets/icons/menu-toggle.svg'
import { ReactComponent as TakiCalendarLogo } from '../../assets/icons/logo.svg'
import { ReactComponent as TeachersIcon } from '../../assets/icons/nav-teachers.svg'
import { ReactComponent as ClassesIcon } from '../../assets/icons/nav-classes.svg'
import { ReactComponent as SubjectsIcon } from '../../assets/icons/nav-subjects.svg'
import { ReactComponent as OffersIcon } from '../../assets/icons/nav-offers.svg'
import { ReactComponent as LevelsIcon } from '../../assets/icons/nav-levels.svg'
import { ReactComponent as StreamingToolsIcon } from '../../assets/icons/nav-streaming-tools.svg'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import { useAuthContext } from '../../context/AuthContext'
import { getCurrentWorkspace } from '../../store/slices/workspacesSlice'
import { useState } from 'react'
import { MultiSelect } from '../Inputs'
import { FILTERS } from '../../constants/filterConfig'
import { Controller, useForm } from 'react-hook-form'
import { fetchOptions } from '../../store/slices/contentSlice'
import { getFormConfig } from '../../utils/getFormConfig'
import { fetchEventsAsync } from '../../store/apis/eventApi'
import { toast } from 'react-toastify'
import * as React from 'react'
import {
  getCurrentCalendarView,
  getCurrentDay,
} from '../../store/slices/calendarSlice'
import _ from 'lodash'
import { isTakiacademy } from '../../utils/workspaceChecker'

const LINKS = ['/', '/members', '/activities']
const TITLES = {
  teachers: { title: 'Teachers', icon: <TeachersIcon /> },
  classes: { title: 'Classes', icon: <ClassesIcon /> },
  subjects: { title: 'Subjects', icon: <SubjectsIcon /> },
  offers: { title: 'Offers', icon: <OffersIcon /> },
  levels: { title: 'Levels', icon: <LevelsIcon /> },
  streamingtools: { title: 'Streaming Tools', icon: <StreamingToolsIcon /> },
}

const MainHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const { sidebarStyle } = useSelector(state => state.setting)
  const { filterDisplay } = useSelector(state => state.calendar)
  let location = useLocation()
  const isActive = LINKS.includes(location.pathname)
  const currentWorkspace = useSelector(getCurrentWorkspace)
  const { user } = useAuthContext[currentWorkspace.name]()
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [isLoading, setIsLoading] = useState(false)
  const currentCalendarView = useSelector(getCurrentCalendarView)
  const currentDay = useSelector(getCurrentDay)
  const { handleSubmit, control, formState, watch, reset } = useForm({
    defaultValues: {
      offerIds: [],
      subjectId: null,
      teacherId: null,
      webinar: null,
    },
  })

  const watchData = watch()
  const handleFilterSubmit = () => {
    setIsLoading(true)
    dispatch(
      fetchEventsAsync({
        date: currentDay,
        calendarView: currentCalendarView,
        search: `${
          watchData.subjectId
            ? `;subjectEvents.subject.id:${watchData.subjectId?.id}`
            : ''
        }${
          watchData.teacherId
            ? `;content.instructor.id:${watchData.teacherId?.id}`
            : ''
        }${
          watchData.offerIds?.length > 0
            ? `;content.groupContents.groups.id:${watchData.offerIds
                ?.map(offer => offer?.id)
                .join(',')}`
            : ''
        }${
          watchData.webinar ? `;webinarAccount:${watchData.webinar?.id}` : ''
        }&searchJoin=and`,
      }),
    )
      .then(res => {
        if (res.payload.status === 201 || res.payload.status === 200) {
          toast.success('filter successfully')
        }
      })
      .catch(() => toast.error('Something went wrong', { autoClose: 300 }))
      .finally(() => {
        setIsLoading(false)
      })
  }
  const handleReset = () => {
    reset()
    dispatch(
      fetchEventsAsync({
        date: currentDay,
        calendarView: currentCalendarView,
      }),
    )
  }

  return (
    <div className={clsx('main_header', isActive && 'main_header__no_border')}>
      <form
        onSubmit={handleSubmit(handleFilterSubmit)}
        className={clsx('main_header__filters', { show: filterDisplay })}>
        {isTakiacademy && (
          <>
            {FILTERS.map(filter => {
              return (
                <Controller
                  key={filter?.id}
                  id={filter?.id}
                  name={filter?.id}
                  control={control}
                  defaultValue={watchData[filter?.id]}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <MultiSelect
                        className='filter-select'
                        isMulti={filter?.isMulti}
                        id={filter?.id}
                        name={filter?.id}
                        onChange={onChange}
                        searchAttribute={filter?.searchAttribute}
                        value={value}
                        loadOptions={fetchOptions[filter?.id]}
                        optionsSelected={watchData[filter?.id]}
                        setOption={value => onChange(filter?.id, value)}
                        optionDisplayAttribute={filter?.optionDisplayAttribute}
                        error={
                          formState.errors[filter?.id] && {
                            state: true,
                            message: formState.errors[filter?.id].message,
                          }
                        }
                        placeholder={filter?.placeholder}
                        customHeight='30px !important'
                      />
                    )
                  }}
                />
              )
            })}
            <input type='submit' value='filter' className='filter-btn' />
            <input
              type='reset'
              onClick={handleReset}
              value='reset'
              className='reset-btn'
            />
          </>
        )}
      </form>
      <div className='main_header__column main_header__left_column'>
        {width <= 800 && (
          <>
            <div
              className='main_header__toggle_btn'
              onClick={() =>
                dispatch(
                  toggleSidebarStatus(
                    sidebarStyle === 'hidden' ? 'open' : 'hidden',
                  ),
                )
              }>
              <ToggleIcon />
            </div>
            <div className='main_header__logo_container'>
              <TakiCalendarLogo className='main_logo' />
            </div>
            <Divider orientation='vertical' variant='middle' flexItem />
          </>
        )}
        {isActive ? (
          <></>
        ) : (
          // <div className='main_header__logo_container'>
          //   {currentWorkspace.name === 'taki' ? (
          //     <TakiAcademyLogo className='main_logo' />
          //   ) : (
          //     <OstedhyLogo />
          //   )}
          //   {width > 800 &&
          //     (currentWorkspace.name === 'taki' ? (
          //       <Typo className='main_header__logo_container__typo' />
          //     ) : (
          //       <div style={{ fontWeight: 'bold' }}>Ostedhy</div>
          //     ))}
          // </div>
          <div className='main_header__title_container'>
            <span className='main_header__title_container__icon'>
              {TITLES[location.pathname.replace(/[/-]/g, '')]?.icon ||
                TITLES[location.state.routeTitle]?.icon}
            </span>
            <span className='main_header__title_container__title'>
              {TITLES[location.pathname.replace(/[/-]/g, '')]?.title ||
                TITLES[location.state.routeTitle]?.title}
            </span>
          </div>
        )}
      </div>
      <div className='main_header__column main_header__right_column'>
        <Divider orientation='vertical' variant='middle' flexItem />
        <div className='main_header__avatar_btn'>
          <DropdownMenu
            handleClose={handleClose}
            anchorEl={anchorEl}
            handleClick={handleClick}
          />
          {width > 800 && (
            <div className='avatar_text' onClick={handleClick}>
              <span className='avatar_text__user'>{user?.username}</span>
              <span className='avatar_text__role'>{user?.role}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MainHeader
