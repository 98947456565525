import React, { useState } from 'react'
import { Dialog, List, ListItem, Radio } from '@mui/material'
import { toast } from 'react-toastify'
import deleteIcon from './../../../assets/images/deleteIcon.png'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEventsAsync } from '../../../store/apis/eventApi'
import {
  getCurrentDay,
  getCurrentCalendarView,
} from '../../../store/slices/calendarSlice'
import Loader from '../../Loader/Loader'
import CloseIcon from '../../SvgIcons/CloseIcon/CloseIcon'
// import ListItemButton from '@mui/material/ListItemButton'
// import ListItemIcon from '@mui/material/ListItemIcon'
// import ListItemText from '@mui/material/ListItemText'
// import { isTakiacademy } from '../../../utils/workspaceChecker'

const DeletePopup = ({ id, open, handleClose, data }) => {
  const [deleteOption, setDeleteOption] = useState(1)
  const [deleteStatus, setDeleteStatus] = useState('idle')
  const currentCalendarView = useSelector(getCurrentCalendarView)
  const currentDay = useSelector(getCurrentDay)
  const dispatch = useDispatch()
  const handleSubmit = async () => {
    const isAllSelected = deleteOption === 3
    const isOccurrencesSelected = deleteOption === 2

    const params = {
      occurrences: isOccurrencesSelected,
      all: isAllSelected,
    }

    setDeleteStatus('pending')
    try {
      const res = await data?.action(params)
      console.log(res)
      handleClose(id)
      setDeleteOption(1)
      setDeleteStatus('success')
      toast.success(`${data?.type} Deleted successfully`)
      dispatch(
        fetchEventsAsync({
          date: currentDay,
          calendarView: currentCalendarView,
        }),
      )
    } catch (error) {
      setDeleteStatus('error')
      toast.error(`Something went wrong`)
    } finally {
      setDeleteStatus('idle')
    }
  }

  // const handleToggle = value => () => setDeleteOption(value)

  // const deleteOptions = [
  //   {
  //     label: 'This Event',
  //     value: 1,
  //   },
  //   {
  //     label: 'This and the following events',
  //     value: 2,
  //   },
  //   {
  //     label: 'All Events',
  //     value: 3,
  //   },
  // ]

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id)
        }
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='ta-modal add-video-modal '>
      <div className='modal'>
        <div className='modal-header'>
          <span>
            <img src={deleteIcon} alt='' />
          </span>
          <span onClick={() => handleClose(id)}>
            <CloseIcon />
          </span>
        </div>
        <div className='modal-content'>
          <p>Delete This {data?.type}</p>
          <p>
            Are you sure you want to delete this {data?.type}? This action
            cannot be undone.
          </p>
        </div>
        {/* {isTakiacademy && (
          <List sx={{ width: '100%', marginTop: '0', marginBottom: '20px' }}>
            {deleteOptions.map(option => {
              const labelId = `checkbox-list-label-${option.value}`
              return (
                <ListItem
                  key={option.value}
                  sx={{
                    height: '40px',
                  }}
                  disablePadding>
                  <ListItemButton
                    sx={{
                      paddingLeft: 0,
                    }}
                    role={undefined}
                    onClick={handleToggle(option.value)}
                    dense>
                    <ListItemIcon
                      sx={{
                        '&.MuiListItemIcon-root': {
                          minWidth: 'fit-content',
                          marginRight: '10px',
                        },
                      }}>
                      <Radio
                        edge='start'
                        checked={option.value === deleteOption}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        sx={{
                          color: '#00AAF9',
                          '&.Mui-checked': {
                            color: '#00AAF9',
                          },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={option.label}
                      sx={{
                        fontSize: '14px',
                        color: '#434343',
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        )} */}

        <div className='modal-buttons'>
          <button onClick={() => handleClose(id)}>Cancel</button>
          <button onClick={handleSubmit}>
            {deleteStatus === 'pending' ? <Loader /> : 'Delete'}
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default DeletePopup
