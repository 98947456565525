import { OSTEDHY_FORM_CONFIG, TAKI_FORM_CONFIG } from '../constants/formConfigs'
import { isTakiacademy } from './workspaceChecker'

export function getFormConfig() {
  if (isTakiacademy) {
    return TAKI_FORM_CONFIG
  } else {
    return OSTEDHY_FORM_CONFIG
  }
}
