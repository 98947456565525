import { defineAbility } from '@casl/ability'

const defineUserAbility = (isAuth, user) => {
  const ability = defineAbility(can => {
    if (!isAuth || !user) {
      return
    }

    const userPermissions = user.permissions || []

    if (
      userPermissions.includes('ROLE_CONTENTEVENTS_EXPORT') ||
      userPermissions.includes('sessions/export')
    ) {
      can('manage', 'event')
    } else if (
      userPermissions.includes('ROLE_CONTENTEVENTS_LIST') ||
      userPermissions.includes('sessions/read')
    ) {
      can('read', 'event')
    } else if (
      userPermissions.includes('ROLE_TRAINEESHIPGROUP_CREATE') ||
      userPermissions.includes('groupSessions/create')
    ) {
      can('create', 'group-session')
    }
  })

  return ability
}

export default defineUserAbility
