import { createAsyncThunk } from '@reduxjs/toolkit'
import { getData, urls } from '../../utils/Connections/AxiosInstance'

export const fetchWebinars = createAsyncThunk(
  'content/fetchWebinars',
  async (params = '', { getState }) => {
    try {
      const response = await getData(urls.webinars(), params)
      const filteredZoomAccounts = response.data.payload.filter(
        account => !account.email.includes('zoom'),
      )
      const state = getState()
      const isEditing = state.modals.isEditing
      return {
        ...response?.data,
        payload: isEditing ? response.data.payload : filteredZoomAccounts,
      }
    } catch (error) {
      console.log(error)
    }
  },
)
