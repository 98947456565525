import clsx from 'clsx'
import React, { forwardRef } from 'react'

const Input = forwardRef(
  (
    {
      id,
      label,
      type,
      value,
      onChange,
      placeholder,
      name,
      register,
      cls,
      error,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={clsx('input', cls, error?.message && 'error')} {...props}>
        <label htmlFor={id} className='input__label'>
          {label}
        </label>
        <input
          ref={ref}
          id={id}
          type={type}
          placeholder={placeholder}
          className='input__field'
          {...register(name)}
        />
        {error?.message ? (
          <p className='input__text-error'>{label} is required</p>
        ) : null}
      </div>
    )
  },
)

export default Input
