import { Navigate } from 'react-router-dom'
import Loading from './Loading'
import { useAuthContext } from '../context/AuthContext'
const GuestGuard = ({ children }) => {
  const currentWorkspace = localStorage.getItem('currentWorkspace')

  const { isAuthenticated, isInitialized, requestStatus } =
    useAuthContext[currentWorkspace]()

  if (!isInitialized && requestStatus === 'loading') {
    return <Loading style={{ width: '100vw', height: '100vh' }} />
  }
  if (isAuthenticated && currentWorkspace) {
    return <Navigate to='/' />
  }

  return <>{children}</>
}

export default GuestGuard
