import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { store } from './store'
import router from './router'
import ModalsProvider from './components/ModalProvider'
import AuthProvider from './context/AuthProvider'
import AbilityProvider from './context/AbilityContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './index.scss'
import ErrorBoundary from './components/ErrorBoundary'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Provider store={store}>
      <AuthProvider>
        <AbilityProvider>
          <ErrorBoundary>
            <ToastContainer
              position='top-right'
              autoClose={2500}
              limit={2}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme='light'
            />
            <RouterProvider router={router} />
            <ModalsProvider />
          </ErrorBoundary>
        </AbilityProvider>
      </AuthProvider>
    </Provider>
  </LocalizationProvider>,

  // </React.StrictMode>,
)
