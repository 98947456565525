import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useCallback,
  useState,
} from 'react'
import { toast } from 'react-toastify'

import { axiosInstance } from '../utils/Connections/AxiosInstance'

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { data } = action.payload
      const role = data.roles[0].split('_')[1]
      const username = `${data.username.split('-').join(' ')}`
      return {
        ...state,
        isAuthenticated: true,
        user: {
          username: username,
          role,
          email: data.email,
          id: data.id,
          permissions: data.admin_groups.flatMap(r => r.roles) || [],
        },
        isInitialized: true,
      }
    }
    case 'RESET': {
      return { ...initialAuthState, isInitialized: true }
    }
    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
      }
    }
    default: {
      return state
    }
  }
}

const TakiAuthContext = createContext({
  ...initialAuthState,
  login: (username, password) => Promise.resolve(),
  logout: () => Promise.resolve(),
  getMe: () => Promise.resolve(),
})

export const TakiAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)
  const [requestStatus, setRequestStatus] = useState('idle')

  const updateRequestStatus = status => {
    setRequestStatus(status)
  }

  const isValidToken = token => {
    return token !== null
  }

  const getMe = useCallback(async () => {
    try {
      const myToken = localStorage.getItem('token')
      if (myToken && isValidToken(myToken)) {
        const response = await axiosInstance.get('/api/admin/me')
        const user = response.data.payload
        dispatch({
          type: 'INITIALIZE',
          payload: {
            data: user,
          },
        })
      } else {
        dispatch({ type: 'RESET' })
      }
    } catch (error) {
      updateRequestStatus('error')
      dispatch({ type: 'RESET' })
    }
  }, [])

  const login = useCallback(
    async (username, password) => {
      updateRequestStatus('loading')
      try {
        const response = await axiosInstance.post(
          '/api/admin/auth/login_check',
          {
            username,
            password,
          },
        )

        const { token } = response.data.payload
        localStorage.setItem('token', token)
        await getMe()

        if (token) {
          toast.success('Login successfully', {
            autoClose: 1500,
          })
          updateRequestStatus('success')
          dispatch({ type: 'LOGIN' })
        }
      } catch (error) {
        toast.error('Invalid credentials', {
          autoClose: 1500,
        })
        updateRequestStatus('error')
        dispatch({ type: 'RESET' })
      }
    },
    [getMe],
  )

  const logout = useCallback(async () => {
    try {
      await axiosInstance.get('/api/admin/auth/logout')
      localStorage.removeItem('token')
      dispatch({ type: 'RESET' })
    } catch (error) {
      toast.error('Something went wrong', {
        autoClose: 1500,
      })
      dispatch({ type: 'RESET' })
    }
  }, [])

  useEffect(() => {
    getMe()
  }, [getMe])

  const authContextValue = useMemo(
    () => ({
      ...state,
      login,
      logout,
      getMe,
      requestStatus,
    }),
    [state, login, logout, getMe, requestStatus],
  )

  return (
    <TakiAuthContext.Provider value={authContextValue}>
      {children}
    </TakiAuthContext.Provider>
  )
}

export const useTakiAuthContext = () => useContext(TakiAuthContext)
