import React from 'react'

const Label = ({ icon, label }) => {
  return (
    <div>
      <img src={icon} alt={label} />
      <span>{label}:</span>
    </div>
  )
}

export default Label
