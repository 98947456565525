import React from 'react'
import clsx from 'clsx'
import { Checkbox } from '@mui/material'
import { ReactComponent as EmptyCheckboxIcon } from '../../assets/icons/ckeckbox-empty.svg'
import CheckedCheckboxIcon from '../SvgIcons/CheckedCheckboxIcon/CheckedCheckboxIcon'

const CustomCheckbox = ({
  cls,
  checked = false,
  onChange,
  label,
  checkedColor,
  disabled,
}) => {
  return (
    <div className={clsx('custom-checkbox', cls)}>
      <Checkbox
        className='class_item__checkbox'
        icon={<EmptyCheckboxIcon />}
        checkedIcon={<CheckedCheckboxIcon checkboxColor={checkedColor} />}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className='check-box_label'>{label}</label>
    </div>
  )
}

export default CustomCheckbox
