import React from 'react'
import Label from './Label'
import List from './List'

const Category = ({ items, icon, label }) => {
  return (
    <div className='category'>
      <Label icon={icon} label={label} />
      <List items={items} />
    </div>
  )
}

export default Category
