import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import {
  getCurrentWorkspace,
  getWorkspaces,
  setWorkspace,
} from '../../store/slices/workspacesSlice'
import arrowRepeat from '../../assets/icons/arrowRepeat.svg'

function Workspace({ style, hideElement }) {
  const [workspaceOpen, setWorkspaceOpen] = useState(false)
  const workspaces = useSelector(getWorkspaces)
  const currentWorkspace = useSelector(getCurrentWorkspace)
  const dispatch = useDispatch()

  const setCurrentWorkspace = workspace => {
    if (workspace.name === currentWorkspace.name) {
      setWorkspaceOpen(false)
      return
    }
    localStorage.setItem('currentWorkspace', workspace.name)
    dispatch(setWorkspace(workspace))
    setWorkspaceOpen(false)
    window.location.reload()
  }

  const filteredWorkspaces = workspaces.filter(
    workspace => workspace.name !== currentWorkspace.name
  )

  return (
    <div
      className={clsx('sidebar__content-workspace', hideElement && 'mini')}
      onBlur={() => setWorkspaceOpen(false)}
      tabIndex='0'
      style={style}
    >
      {workspaceOpen && (
        <>
          <div className='sidebar__content-workspace-field-content'>
            {filteredWorkspaces.map(workspace => (
              <div
                className='sidebar__content-workspace-field'
                key={workspace.name}
                onClick={() => setCurrentWorkspace(workspace)}
              >
                <div className={clsx('field-name', hideElement && 'mini')}>
                  <p className='arText'>{workspace.label}</p>
                </div>
                <div className={clsx('field-thumbnail', hideElement && 'mini')}>
                  <img src={workspace?.thumbnail} alt='thumbnail' />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div
        className={clsx('sidebar__content-workspace-default', hideElement && 'mini')}
        onClick={() => setWorkspaceOpen(prev => !prev)}
      >
        <div className={clsx('sidebar__content-workspace-icon', hideElement && 'mini')}>
          <img src={arrowRepeat} alt='error' />
        </div>
        <div className='sidebar__content-workspace-field'>
          <div className={clsx('field-name', hideElement && 'mini')}>
            <p className='arText'>{currentWorkspace.label}</p>
          </div>
          <div className={clsx('field-thumbnail', hideElement && 'mini')}>
            <img src={currentWorkspace?.thumbnail} alt='thumbnail' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Workspace
