import ZoomLogo from '../../assets/images/tools-zoom-logo.png'
import GotoWebinarLogo from '../../assets/images/tools-goto-logo.png'
import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  streamingTools: [
    {
      id: 1,
      description:
        'In this together. Keeping you securely connected wherever you are.',
      icon: ZoomLogo,
      title: 'Zoom Rooms',
      company: '',
    },
    {
      id: 2,
      description: 'One solution. So many ways to stay connected.',
      icon: GotoWebinarLogo,
      title: 'gotowebinar',
      company: '',
    },
  ],
}

export const streamingToolsSlice = createSlice({
  name: 'streamingTool',
  initialState,
  reducers: {},
})

export const getStreamingTools = state => state.streamingTool.streamingTools
export default streamingToolsSlice.reducer
