import React, { useMemo, useState } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import { useTheme } from '@mui/material'
import { useDispatch } from 'react-redux'
import { isOstedhy, isTakiacademy } from '../../../utils/workspaceChecker'
import clsx from 'clsx'

const MultiSelect = ({
  loadOptions,
  setOption,
  optionsSelected,
  error = { state: false, message: '' },
  placeholder = 'Select',
  isMulti = true,
  optionDisplayAttribute,
  label,
  register,
  disabled = false,
  searchJoin,
  searchAttribute,
  name,
  customHeight = '56px !important',
  className,
  ...props
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [searchInput, setSearchInput] = useState('')
  const optionDisplayAttributeArray = useMemo(
    () => optionDisplayAttribute.split(','),
    [optionDisplayAttribute],
  )

  const getOptions = async (q, prevOptions, { page }) => {
    try {
      const queryOptions = {
        search:
          name === 'teacherId'
            ? `id:${searchInput};name:${searchInput};lastName:${searchInput};email:${searchInput};phone:${searchInput}&searchJoin=or`
            : name === 'offerIds'
            ? `active:1;name:${searchInput}&;searchJoin=and`
            : name === 'subjectId'
            ? `visible:1;name_and_division:${searchInput}&;searchJoin=and&orderBy=id&sortedBy=desc`
            : `${searchAttribute}:${searchInput}&searchJoin=or`,
      }

      if (isOstedhy) {
        queryOptions.query = encodeURIComponent(
          JSON.stringify({
            or: [{ or: [{ [searchAttribute]: { like: `%${searchInput}%` } }] }],
          }),
        )
        delete queryOptions.search
      }
      const response = await dispatch(
        searchInput ? loadOptions(queryOptions) : loadOptions({ page }),
      ).then(result => result)

      function removeDuplicateObjects(arr) {
        const uniqueArray = []

        for (const obj of arr) {
          // Check if the object already exists in the uniqueArray
          const isDuplicate = uniqueArray.some(
            item => JSON.stringify(item) === JSON.stringify(obj),
          )

          // If it's not a duplicate, add it to the uniqueArray
          if (!isDuplicate) {
            uniqueArray.push(obj)
          }
        }

        return uniqueArray
      }

      const options = removeDuplicateObjects(response?.payload?.payload)
      const hasMorePages = isTakiacademy
        ? response.payload.meta.current_page !== response.payload.meta.last_page
        : response?.payload?.metadata?.total_items > prevOptions.length

      return {
        options: options || [],
        hasMore: hasMorePages,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  const styles = useMemo(
    () => ({
      singleValue: styles => {
        return {
          ...styles,
          color: error.state
            ? '#ED3863'
            : `${
                theme.palette.mode === 'light'
                  ? '#000 !important'
                  : '#e6e5e8 !important'
              }`,
        }
      },
      multiValueLabel: styles => {
        return {
          ...styles,
          marginRight: '3px',
          color: `${theme.palette.mode === 'light' ? '#000' : '#fff'}`,
        }
      },
      multiValue: styles => {
        return {
          ...styles,
          padding: '4px',
          backgroundColor: `#eee !important`,
        }
      },
      multiValueRemove: styles => {
        return { ...styles, cursor: 'pointer' }
      },
      clearIndicator: styles => {
        return { ...styles, '&:hover': { color: 'unset' }, cursor: 'pointer' }
      },

      placeholder: styles => {
        return {
          ...styles,
          color: `${
            error.state ? '#ED3863' : theme.palette.text.secondary
          } !important`,
        }
      },
      input: (styles, { isFocused, isSelected, ...rest }) => {
        return {
          ...styles,
          color: `${theme.palette.mode === 'light' ? '#000' : '#eee'}`,
          minWidth: '20px',
        }
      },
      control: (styles, { isFocused, isSelected, ...rest }) => {
        return {
          ...styles,
          cursor: 'pointer',
          backgroundColor: `${theme.palette.background.light} !important`,
          boxShadow: 'none !important',
          outline: 'none',
          borderColor: 'unset',
          borderStyle: 'unset',
          borderWidth: 'unset',
          border: error.state
            ? `1px solid ${theme.palette.error.main}`
            : isFocused
            ? `1px solid ${theme.palette.action.disabled} !important`
            : `1px solid #e8e8e8 !important`,
          borderRadius: '12px !important',
          minHeight: customHeight,
          height: '2.8rem',
          overflow: 'auto',
          '&:hover': {
            borderColor: 'none !important',
            borderStyle: 'none !important',
            borderWidth: 'none !important',
            outline: 'none !important',
            border: `${
              error.state
                ? `1px solid #ED3863 !important`
                : !isFocused
                ? '1px solid hsl(0, 0%, 80%) !important'
                : '1px solid #aaa !important'
            }`,
          },
        }
      },
      menuList: styles => ({
        ...styles,
        background: `${theme.palette.background.dark} !important`,
        borderRadius: '12px !important',
      }),
      option: (styles, state) => {
        state['theme']['colors']['primary90'] = '#C5E0FC'
        return {
          ...styles,
          background:
            document.body.offsetWidth < 500
              ? 'none !important'
              : state.isFocused
              ? `#00AAF9 !important`
              : state.isSelected
              ? `#00AAF9 !important`
              : state.isDisabled
              ? '#00AAF9'
              : undefined,
          color: state.isFocused
            ? `${theme.palette.mode === 'light' ? '#fff' : ''}`
            : '',
          zIndex: 1,
          cursor: 'pointer',
        }
      },
      menu: base => ({ ...base, zIndex: 100 }),
      indicatorSeparator: base => ({ ...base, display: 'none' }),
      indicatorsContainer: base => ({
        ...base,
        paddingRight: '10px',
      }),
    }),
    [theme, error],
  )

  return (
    <div className={clsx(className)}>
      <label className='multi-select__label'>{label}</label>
      <AsyncPaginate
        isDisabled={disabled}
        additional={{ page: 1 }}
        loadOptions={getOptions}
        value={optionsSelected}
        onInputChange={value => setSearchInput(() => value)}
        onChange={() => setOption()}
        getOptionLabel={option => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              {option?.thumbnail && (
                <img
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    objectFit: 'contain',
                  }}
                  src={option?.thumbnail}
                  alt='icon'
                />
              )}
              {name === 'subjectId' ? `${option?.division?.name} || ` : null}
              {optionDisplayAttributeArray
                .map(attr => option[attr])
                .reduce((attr, acc) => attr + ' ' + acc)}
            </div>
          )
        }}
        getOptionValue={option => {
          return option.id
        }}
        placeholder={placeholder}
        isMultiadditional={{ page: 1 }}
        isMulti={isMulti}
        styles={styles}
        isClearable={true}
        {...props}
      />
      <p style={{ fontSize: '11px', color: '#ED3863', fontWeight: 500 }}>
        {error.state && error.message}
      </p>
    </div>
  )
}

export default MultiSelect
