import clsx from 'clsx'
import { NavLink, useLocation } from 'react-router-dom'
import { ReactComponent as DashboardIcon } from '../../assets/icons/nav-dashboard.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/nav-calendar.svg'
import { ReactComponent as TeachersIcon } from '../../assets/icons/nav-teachers.svg'
import { ReactComponent as ClassesIcon } from '../../assets/icons/nav-classes.svg'
import { ReactComponent as SubjectsIcon } from '../../assets/icons/nav-subjects.svg'
import { ReactComponent as OffersIcon } from '../../assets/icons/nav-offers.svg'
import { ReactComponent as LevelsIcon } from '../../assets/icons/nav-levels.svg'
import { ReactComponent as StreamingToolsIcon } from '../../assets/icons/nav-streaming-tools.svg'
import { ReactComponent as SettingsIcon } from '../../assets/icons/nav-settings.svg'

// Options:
// type: {"route-link", "bouton", "external-link"}
// path: path to route
// url: url to external link
// onClickAction: func invokes when an event occurs
// label: text appears
// iconName: {"dashboardIcon", "calendarIcon", "teachersIcon", "classesIcon", "subjectsIcon", "offersIcon", "levelsIcon", "streamingToolsIcon", "settingsIcon"}

const ICONS = {
  dashboardIcon: <DashboardIcon />,
  calendarIcon: <CalendarIcon />,
  teachersIcon: <TeachersIcon />,
  classesIcon: <ClassesIcon />,
  subjectsIcon: <SubjectsIcon />,
  offersIcon: <OffersIcon />,
  levelsIcon: <LevelsIcon />,
  streamingToolsIcon: <StreamingToolsIcon />,
  settingsIcon: <SettingsIcon />,
}

const LINKS = ['/', '/members', '/activities']

const NavigationContent = ({ iconName, label }) => {
  return (
    <>
      <div className='navigation_content__icon'>{ICONS[iconName]}</div>
      <span className='navigation_content__label'>{label}</span>
    </>
  )
}

const NavigationLink = ({
  type = 'route-link',
  path = '/',
  onClickAction = () => {},
  label = 'Dashboard',
  iconName = 'dashboardIcon',
}) => {
  let location = useLocation()
  const isActive = LINKS.includes(location.pathname) && label === 'My Calendar'

  if (type === 'bouton')
    return (
      <div onClick={onClickAction} className='navigation_link'>
        <NavigationContent iconName={iconName} label={label} />
      </div>
    )

  if (type === 'external-link')
    return (
      <a
        href={path}
        target='_blank'
        rel='noopener noreferrer'
        className='navigation_link'
        onClick={onClickAction}>
        <NavigationContent iconName={iconName} label={label} />
      </a>
    )

  return (
    <NavLink
      to={path}
      className={clsx('navigation_link', isActive && 'active')}
      onClick={onClickAction}>
      <NavigationContent iconName={iconName} label={label} />
    </NavLink>
  )
}

export default NavigationLink
