import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
  Dialog,
  FormControlLabel,
  MenuItem,
  Radio,
  Select,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icon.svg'
import clsx from 'clsx'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import { openModal } from '../../../store/slices/modalsSlice'
import { RepeatInput } from '../../Inputs'
import { Button } from '../../Form'
import { duplicateEvent } from '../../../store/apis/eventApi'
import removeNullishValues from '../../../utils/removeNullishValues'
import {
  getDailyDates,
  getMonthlyDates,
  getWeekNumber,
  getWeeklyDates,
} from '../../../utils/duplicatedEventDates'
import {
  DAYS_LABELS,
  INTERVAL_REPETITIONS_DEFAULT,
  WEEKS_LABELS,
} from '../../../constants'

dayjs.extend(isBetween)
dayjs.extend(customParseFormat)
dayjs.extend(timezone)

const CustomIteration = ({ id, open, handleClose, data: eventData }) => {
  const dispatch = useDispatch()

  const [intervalReps, setIntervalReps] = useState(INTERVAL_REPETITIONS_DEFAULT)
  const [selectedEnd, setSelectedEnd] = useState('on')
  const [endsOn, setEndsOn] = useState(null)
  const [selectedDays, setSelectedDays] = useState([])
  const [loading, setLoading] = useState(false)
  const [monthlyDuplicationType, setMonthlyDuplicationType] = useState(0)
  const [monthlyDuplicationOptions, setMonthlyDuplicationOption] = useState([])
  const [errors, setErrors] = useState({
    endsOn: {
      hasError: false,
      message: null,
    },
    weekdays: {
      hasError: false,
      message: null,
    },
  })

  useEffect(() => {
    if (isWeekly) {
      if (selectedDays.map(d => d.id).length === 0) {
        setErrors(prev => {
          const prevCopy = { ...prev }
          return {
            ...prevCopy,
            weekdays: {
              hasError: true,
              message: 'Please select a day',
            },
          }
        })
      } else {
        setErrors(prev => {
          const prevCopy = { ...prev }
          return {
            ...prevCopy,
            weekdays: {
              hasError: false,
              message: null,
            },
          }
        })
      }
    }
  }, [endsOn, selectedDays.length])

  const MONTHLY_DUPLICATE_OPTIONS = [
    {
      value: 0,
      label: ` Monthly on day ${dayjs(eventData?.start_date).format('DD')}`,
    },
    {
      value: 1,
      label: `  Monthly on the ${
        WEEKS_LABELS[getWeekNumber(eventData?.start_date)]
      } week ${dayjs(eventData?.start_date).format('dddd')}`,
    },
  ]

  useEffect(() => {
    setMonthlyDuplicationOption(MONTHLY_DUPLICATE_OPTIONS)
  }, [eventData?.start_date])
  const handleCloseModal = (e, reason) => {
    if (reason === 'backdropClick' && reason !== 'escapeKeyDown') {
      handleClose(id)
    }
  }
  const isZoomEvent = eventData?.webinar_account?.email
    .toLowerCase()
    .includes('zoom' || 'support')

  const isDaily = intervalReps?.selectedInterval.value === 1
  const isWeekly = intervalReps?.selectedInterval.value === 2
  const isMonthly = intervalReps?.selectedInterval.value === 3
  const interval = intervalReps?.repetitions
  const sameDateMonthly = monthlyDuplicationType === 0
  const sameDayAndWeekMonthly = monthlyDuplicationType === 1

  const duplicatedEventDates = (currentDate, endDateTime, specifiedDays) => {
    let dates = []

    if (isWeekly) {
      dates = getWeeklyDates(
        specifiedDays,
        interval,
        currentDate,
        endDateTime,
        isZoomEvent,
      )
    } else if (isDaily) {
      dates = getDailyDates(currentDate, interval, endDateTime)
    } else if (isMonthly) {
      const isExactDateEachMonth = Boolean(monthlyDuplicationType)
      dates = getMonthlyDates(
        currentDate,
        endDateTime,
        interval,
        isZoomEvent,
        isExactDateEachMonth,
      )
    }

    return dates
  }
  const handleSave = async () => {
    const selectedWeekDays = selectedDays.map(d => d.id)
    const noEndsDateTime = !endsOn
    const noWeekdaysSelected = selectedWeekDays.length === 0
    if (isWeekly) {
      if (noWeekdaysSelected) {
        setErrors(prev => {
          const prevCopy = { ...prev }
          return {
            ...prevCopy,
            weekdays: {
              hasError: true,
              message: 'Please select a day',
            },
          }
        })
      }
    }
    if (noEndsDateTime) {
      setErrors(prev => ({
        ...prev,
        endsOn: {
          hasError: true,
          message: 'You must select end date',
        },
      }))
    }

    if (isWeekly && noWeekdaysSelected) return
    if (noEndsDateTime) return

    const dates = duplicatedEventDates(
      eventData?.start_date,
      dayjs(endsOn),
      isWeekly ? selectedDays.map(d => d.id) : intervalReps?.repetitions,
    )
    const typeOfSelection = intervalReps.selectedInterval.value
    let formData = new FormData()

    const data = {
      eventId: eventData?.id,
      type: typeOfSelection,
      monthlyWeek:
        isMonthly && sameDayAndWeekMonthly ? dates.monthlyWeek : null,
      monthlyDay: isMonthly && sameDateMonthly ? dates.monthlyDay : null,
      monthlyWeekDay:
        isMonthly && sameDayAndWeekMonthly ? dates.monthlyWeekDay : null,
      endDateTime: dayjs(endsOn).format('YYYY-MM-DD HH:mm:ss'),
      weekDays: isWeekly
        ? selectedDays.map(d => (isZoomEvent ? d.id + 1 : d.id)).join(',')
        : undefined,
      repeatInterval: intervalReps?.repetitions,
    }

    const duplicateEventDates = isMonthly ? dates.dates : dates

    for (const [key, value] of Object.entries(removeNullishValues(data))) {
      formData.append(key, value)
    }
    duplicateEventDates.forEach((date, i) =>
      formData.append(`dates[${i}]`, date),
    )

    try {
      setLoading(true)
      await dispatch(duplicateEvent(formData)).unwrap()

      setIntervalReps(INTERVAL_REPETITIONS_DEFAULT)
      setEndsOn(null)
      setSelectedDays([])
      setErrors({
        endsOn: {
          hasError: false,
          message: null,
        },
        weekdays: {
          hasError: false,
          message: null,
        },
      })
      setMonthlyDuplicationType(0)
      toast.success('Event successfully duplicated')
      handleClose(id)
    } catch (error) {
      toast.error(error?.message || 'Could not duplicate event')
    } finally {
      setLoading(false)
    }
  }
  const handleSelectDay = day => {
    setSelectedDays(prev => {
      const prevCopy = [...prev]
      if (selectedDays.some(select => select.id === day.id)) {
        return prevCopy.filter(select => select.id !== day.id)
      } else {
        return [...prevCopy, day]
      }
    })
  }
  const handleMonthlyDuplicationType = value => setMonthlyDuplicationType(value)

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='ta-modal add-video-modal '>
      <div className='custom-iteration'>
        <div className='custom-iteration__header'>
          <div>
            <span className='subtitle'>Duplicate Session</span>
            <span className='label'>{eventData?.name}</span>
          </div>
          <span
            className='close-btn'
            onClick={() => {
              handleClose(id)
              dispatch(openModal('event', eventData))
              setIntervalReps(INTERVAL_REPETITIONS_DEFAULT)
              setEndsOn(null)
              setSelectedDays([])
              setErrors({
                endsOn: {
                  hasError: false,
                  message: null,
                },
                weekdays: {
                  hasError: false,
                  message: null,
                },
              })
            }}></span>
        </div>
        <div className='custom-iteration__container'>
          <RepeatInput onChange={setIntervalReps} />
          <div className={clsx('day-reps', { hide: !isWeekly })}>
            <label className='label'>Repeat on</label>
            {errors.weekdays.hasError && (
              <p className='error'>{errors.weekdays.message}</p>
            )}
            <div className='days'>
              {DAYS_LABELS.map(day => (
                <div
                  className={clsx('day', {
                    selected: selectedDays.some(select => select.id === day.id),
                  })}
                  key={day.id}
                  onClick={() => handleSelectDay(day)}>
                  {day.label}
                </div>
              ))}
            </div>
          </div>
          {isMonthly && (
            <Select
              sx={{
                width: '75%',
                borderRadius: '12px',
                height: '50px',
                marginLeft: '108px',
              }}
              id='monthly-duplicate'
              value={monthlyDuplicationType}>
              {monthlyDuplicationOptions.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  onClick={() => handleMonthlyDuplicationType(option.value)}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}

          <div className='ends'>
            <p className='label'>Ends</p>
            <div className='option'>
              <FormControlLabel
                value='on'
                control={<Radio checked={selectedEnd === 'on'} />}
                label='On'
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                }}
                onChange={e => setSelectedEnd(e.target.value)}
              />
              <DesktopDatePicker
                slots={{
                  openPickerIcon: CalendarIcon,
                }}
                placeholder='Select you date'
                format='YYYY-MM-DD'
                disablePast
                onChange={value => {
                  setErrors(prev => ({
                    ...prev,
                    endsOn: {
                      hasError: false,
                      message: null,
                    },
                  }))
                  setEndsOn(value)
                }}
                maxDate={dayjs.tz(eventData?.start_date).add(3, 'month')}
                onError={err => err}
                slotProps={{
                  textField: {
                    helperText: errors.endsOn.hasError
                      ? errors.endsOn.message
                      : null,
                  },
                }}
                sx={{
                  borderRadius: '12px',
                  marginTop: '15px',
                  width: '215px',
                  '& p': {
                    color: errors.endsOn.hasError && '#ed3863',
                    marginLeft: '0',
                  },
                  fieldset: {
                    borderColor: errors.endsOn.hasError && '#ed3863',
                  },
                }}
              />
            </div>
          </div>
          <Button onClick={handleSave} disabled={loading}>
            {loading ? 'loading...' : 'Save'}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default CustomIteration
