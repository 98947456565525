import { createSlice, current } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import extractEventData from '../../utils/extractEventData'
import { fetchEventsAsync } from '../apis/eventApi'

const initialState = {
  calendarFiltersStyle: 'open',
  events: [],
  currentDay: dayjs().toString(),
  status: 'idle',
  view: localStorage.getItem('view') || 'day',
  filterDisplay: false,
}

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    toggleCalendarFiltersStatus: (state, action) => {
      const status = action.payload
      if (status) {
        state.calendarFiltersStyle = status
      } else {
        state.calendarFiltersStyle =
          current(state).calendarFiltersStyle === 'hidden' ? 'open' : 'hidden'
      }
    },
    toggleCalendarFilterDisplay: state => {
      state.filterDisplay = !state.filterDisplay
    },

    setView: (state, action) => {
      state.view = action.payload
      localStorage.setItem('view', action.payload)
    },
    selectDay: (state, action) => {
      state.currentDay = action.payload
    },
    incrementDay: state => {
      const isDayView = state.view === 'day'
      const currentDay = isDayView
        ? dayjs(state?.currentDay).add(1, 'day').toString()
        : dayjs(state?.currentDay).add(1, 'week').toString()
      state.currentDay = currentDay
    },
    decrementDay: state => {
      const isDayView = state.view === 'day'
      const currentDay = isDayView
        ? dayjs(state?.currentDay).subtract(1, 'day').toString()
        : dayjs(state?.currentDay).subtract(1, 'week').toString()
      state.currentDay = currentDay
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEventsAsync.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchEventsAsync.fulfilled, (state, action) => {
        state.status = 'success'
        state.events = action?.payload?.map(event => extractEventData(event))
      })
      .addCase(fetchEventsAsync.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const getEvents = state => state.calendar.events
export const getEventsStatus = state => state.calendar.status
export const getCurrentDay = state => state.calendar.currentDay
export const getCurrentCalendarView = state => state.calendar.view
export const getCalendarFiltersStyle = state =>
  state.calendar.calendarFiltersStyle

export const {
  toggleCalendarFiltersStatus,
  selectClassById,
  selectDay,
  incrementDay,
  decrementDay,
  setView,
  toggleCalendarFilterDisplay,
} = calendarSlice.actions

export default calendarSlice.reducer
