import { lazy } from 'react'
import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
} from 'react-router-dom'
import MainLayout from './layouts/MainLayout'
import CalendarLayout from './layouts/CalendarLayout'
import GuestLayout from './layouts/GuestLayout/GuestLayout'

const MyCalendarView = lazy(() => import('./views/MyCalendar'))
const MembersView = lazy(() => import('./views/Members'))
const ActivitiesView = lazy(() => import('./views/Activities'))
const TeachersView = lazy(() => import('./views/Teachers'))
const ClassesView = lazy(() => import('./views/Classes'))
const SubjectsView = lazy(() => import('./views/Subjects'))
const OffersView = lazy(() => import('./views/Offers'))
const LevelsView = lazy(() => import('./views/Levels'))
const StreamingToolsView = lazy(() => import('./views/StreamingTools'))
const CategoryListView = lazy(() => import('./views/CategoryList'))
const LoginView = lazy(() => import('./views/Login'))
const NotFoundView = lazy(() => import('./views/NotFound'))

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={<GuestLayout />}>
        <Route path='login' element={<LoginView />} />
      </Route>
      <Route
        element={<MainLayout />}
        errorElement={<NotFoundView />}
        ErrorBoundary={<NotFoundView />}>
        <Route path='/' element={<CalendarLayout />}>
          <Route index element={<MyCalendarView />} />
          <Route path='members' element={<MembersView />} />
          <Route path='activities' element={<ActivitiesView />} />
        </Route>
        <Route path='teachers' element={<TeachersView />} />
        <Route path='classes' element={<ClassesView />} />
        <Route path='subjects' element={<SubjectsView />} />
        <Route path='offers' element={<OffersView />} />
        <Route path='levels' element={<LevelsView />} />
        <Route path='streaming-tools' element={<StreamingToolsView />} />
        <Route path='streaming-tools/:id' element={<CategoryListView />} />
      </Route>
      <Route path='*' element={<NotFoundView />} />
    </>,
  ),
)

export default router
